@import url(//fonts.googleapis.com/earlyaccess/nanummyeongjo.css);
@import url(//fonts.googleapis.com/earlyaccess/nanumgothic.css);
@import url(/_src/NanumSquare/nanumsquare.css);
/***** Top Notice *****/
/***** Header *****/
/***** Content *****/
/***** FOOTER *****/
@media only screen and (max-width: 708px) {
  .wide {
    display: none;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .narrow {
    display: none;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .narrow {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .narrow {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .narrow {
    display: none;
  }
}

@media only screen and (max-width: 708px) {
  .responsive-hide.lv1 {
    display: none;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .responsive-hide.lv2 {
    display: none;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .responsive-hide.lv3 {
    display: none;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .responsive-hide.lv4 {
    display: none;
  }
}

@media only screen and (min-width: 1280px) {
  .responsive-hide.lv5 {
    display: none;
  }
}

.tab-panel {
  display: none;
}

.tab-panel.selected {
  display: block;
}

.box-pagination {
  text-align: center;
}

#container {
  min-height: 100%;
  padding-bottom: 151px;
  background-color: #f4f4f4;
  position: relative;
}

@media only screen and (max-width: 708px) {
  #container {
    padding-top: 48px;
    margin-left: 0px !important;
    padding-bottom: 230px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #container {
    padding-top: 80px;
    padding-bottom: 230px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #container {
    padding-top: 80px;
    padding-bottom: 230px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #container {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 1280px) {
  #container {
    padding-top: 80px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #container.two-rows {
    margin: 0 auto 0;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #container.two-rows {
    margin: 0 auto 0;
  }
}

#container.two-rows #center-container {
  margin-top: 80px;
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #container.two-rows #center-container {
    width: 850px;
    margin: 80px auto 0;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #container.two-rows #center-container {
    width: 1200px;
    margin: 80px auto 0;
  }
}

.wrapper {
  width: 100%;
  min-height: 190px;
}

.inner {
  margin: 0 auto 0;
  position: relative;
}

@media only screen and (max-width: 708px) {
  .inner {
    width: 100%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .inner {
    max-width: 708px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .inner {
    max-width: 816px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .inner {
    padding: 0 30px 0px;
    max-width: 1024px;
  }
}

@media only screen and (min-width: 1280px) {
  .inner {
    padding: 0 40px 0px;
    max-width: 1280px;
  }
}

.dropdown-menu {
  padding: 0px;
  margin: 0px;
  min-width: 100px;
}

.openContentModal {
  cursor: pointer;
  color: #5190d7;
}

html, body {
  height: 100%;
}

#body-container {
  min-height: 100%;
  position: relative;
}

/* OverWrite Bootstrap */
body, h1, h2, h3, h4, input, button {
  font-family: NanumSquare, "Apple SD Neo Gothic", "malgun gothic", dotum, sans-serif;
}

ul {
  margin: 0px;
}

p {
  margin: 0px;
}

.row {
  margin: 0px;
}

.modal_content {
  padding: 8px;
}

#media_up {
  margin-top: 15px;
}

.pagination {
  margin: 8px;
}

pre {
  padding: 0px;
  margin: 0px;
  border: 0px;
  background-color: transparent;
}

img {
  -ms-interpolation-mode: bicubic;
}

/* Common */
body {
  background-color: #f4f4f4 !important;
}

* {
  margin: 0px;
  padding: 0px;
}

.cr, .clear {
  clear: both;
}

textarea {
  resize: none;
}

a {
  text-decoration: none !important;
  cursor: pointer;
  color: #3f3f3f;
}

a:hover {
  text-decoration: none !important;
  color: #1c1c1c;
}

a:active {
  text-decoration: none !important;
}

a.selected {
  text-decoration: none !important;
}

.hidden {
  visibility: hidden;
}

.none {
  display: none;
}

.none.selected {
  display: block;
}

ul li {
  display: block;
}

.fl, .leftBox {
  float: left;
}

.fr, .rightBox {
  float: right;
}

.mr {
  margin-right: 8px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-32 {
  margin-right: 32px;
}

.ml {
  margin-left: 8px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-right: 20px;
}

.ml-32 {
  margin-left: 32px;
}

.mt {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.mb {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.centerBox {
  margin: 0px auto;
  text-align: center;
}

.centerText {
  text-align: center;
}

.centerTextBox {
  text-align: center;
}

.prevImgBox {
  width: 250px;
  height: 250px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
}

.errorMsg {
  color: red;
}

.img-circle {
  border-radius: 50%;
}

.circle {
  border-radius: 50%;
}

.xeicon {
  font-family: xeicon;
}

.explain {
  font-size: 12px;
}

.alignCenter {
  text-align: center;
}

.border-top {
  border-top: 1px solid #e2e2e2;
}

.border-bottom {
  border-bottom: 1px solid #e2e2e2;
}

.lst > ul > li {
  float: left;
  margin-left: 10px;
}

.lst ul li:first-child {
  margin-left: 0px;
}

.btnLst ul li {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  cursor: pointer;
  padding: 8px;
  margin: 0px;
}

.btnLst ul li.selected {
  background-color: #474747;
  color: white;
}

.btnLst ul li:first-child {
  border-left: 1px solid #ccc;
}

#uiBlock {
  background-color: black;
  display: none;
  -moz-opacity: 0.75;
  -khtml-opacity: 0.75;
  opacity: 0.75;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha" Opacity=75;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=75);
  filter: alpha(opacity=75);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  -webkit-transition: all 2s;
  -moz-transition: all 2s;
  -ms-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s;
}

#dismiss {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: none;
  -webkit-transition: all 2s;
  -moz-transition: all 2s;
  -ms-transition: all 2s;
  -o-transition: all 2s;
  transition: all 2s;
}

#dismiss.leftToggled {
  left: 260px;
  top: 0px;
}

#uiBlock.toggled-left-sidebar {
  display: block;
}

#dismiss.toggled-left-sidebar {
  display: block;
}

#uiBlock.toggled-search-bar {
  display: block;
}

#dismiss.toggled-search-bar {
  display: block;
}

#uiBlock.toggled-right-sidebar {
  display: block;
}

#dismiss.toggled-right-sidebar {
  display: block;
}

#uiBlock.mode-cinema {
  display: block;
}

#dismiss.mode-cinema {
  display: block;
}

/* Common Ad Zone */
#mobile_bottom_ad_wrapper {
  display: none;
  position: fixed;
  vertical-align: bottom;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 50px;
  background-color: white;
  z-index: 2147483647;
}

#mobile_bottom_ad {
  display: none;
  width: 320px;
  height: 50px;
  z-index: 999;
  margin: 0 auto 0;
}

#socialLogin {
  width: 290px;
  margin: 0 auto 0;
}

/* ====================== Navi ====================== */
.childNavi {
  display: none;
}

.parentNavi {
  cursor: pointer;
}

/* ====================== Header ====================== */
.header-search-bar {
  top: 0px;
  position: fixed;
  display: none;
  z-index: 1000;
  background-color: #3476b7;
}

.header-search-bar #btn_goSearch {
  cursor: pointer;
}

.header-search-bar input, .header-search-bar span {
  border: 0px;
}

/* ====================== Content Containers ====================== */
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chLang {
  padding: 5px;
  cursor: pointer;
}

/* Utils */
.tagBox ul li {
  border: 1px solid #ccc;
  border-radius: 6px;
}

.tagRemove {
  padding: 5px 6px 3px 6px;
  border-right: 1px solid #ccc;
}

.tagRemove i {
  padding: 3px 10px;
  color: #de747e;
  cursor: pointer;
  font-size: 20px;
}

.tagLabel {
  padding: 3px 10px;
  font-size: 14px;
  text-align: center;
}

/*  Boxes */
.box-empty {
  padding: 30px;
  text-align: center;
  font-size: 20px;
}

.facebook {
  background-color: #3c5a98;
}

.twitter {
  background-color: #55acef;
}

.google {
  background-color: #de4b39;
}

.round {
  padding: 10px 15px;
  -webkit-border-top-left-radius: 20px;
  -webkit-border-top-right-radius: 20px;
  -webkit-border-bottom-right-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-topright: 20px;
  -moz-border-radius-bottomright: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.round-semi {
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.round-semi-left {
  -webkit-border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.round-semi-right {
  -webkit-border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-topright: 4px;
  -moz-border-radius-bottomright: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.round-semi-top {
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.round-semi-bottom {
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.corverBorder {
  width: 100%;
  height: 180px;
  background-image: url("/_src/common/pageCover.png");
  background-position: bottom center;
  background-repeat: repeat-x;
  position: absolute;
  top: 0;
}

/* ====================== Footer ====================== */
#footer {
  background-color: #f3f3f5;
  min-height: 121px;
  border-top: 1px solid #d8dbe1;
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-top: 25px;
  padding-top: 22px;
}

#footer #footer-logo-box {
  width: 100%;
  text-align: center;
}

#footer #footer-box-logo #footer-logo {
  background-size: contain;
  width: 200px;
  height: 42px;
  display: block;
  background-repeat: no-repeat;
  filter: alpha(opacity=90);
  -moz-opacity: 0.90;
  -khtml-opacity: 0.90;
  opacity: 0.90;
}

#footer .links {
  text-align: center;
  padding: 3px;
}

#footer .links li {
  text-align: center;
  font-size: 12px;
  float: left;
}

#footer .links li a {
  color: #808080;
}

#footer .links li a:hover {
  color: #454545;
}

#footer .links li .privacy {
  font-weight: bold;
  color: #333333;
}

#footer .footer-middle {
  margin-bottom: 15px;
  height: 20px;
  overflow: hidden;
}

@media only screen and (max-width: 708px) {
  #footer .footer-middle {
    padding: 0px 10px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #footer .footer-middle {
    padding: 0px 10px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #footer .footer-middle {
    padding: 0px 10px;
  }
}

#footer .footer-middle .links-social {
  margin-top: 7px;
}

#footer .footer-middle .links-social li a {
  font-size: 15px;
}

#footer .footer-middle .wrapper-links {
  height: 20px;
  position: relative;
}

@media only screen and (max-width: 708px) {
  #footer .footer-bottom {
    padding: 10px;
    text-align: center;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #footer .footer-bottom {
    padding: 10px;
    text-align: center;
  }
}

@media only screen and (max-width: 708px) {
  #footer .footer-info {
    display: block;
    text-align: center;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #footer .footer-info {
    display: block;
    text-align: center;
  }
}

#footer #btn-family {
  color: #dadada;
  font-size: 10px;
  margin-left: 20px;
  cursor: pointer;
}

.address {
  color: #656565;
  margin-bottom: 4px;
  font-size: 12px;
}

.copyright {
  font-size: 11px;
  color: #656565;
  filter: alpha(opacity=70);
  -moz-opacity: 0.70;
  -khtml-opacity: 0.70;
  opacity: 0.70;
}

.bar-top {
  height: 25px;
  background-color: #393939;
  color: #e7e7e7;
}

.bar-top a {
  color: #f0f0f0;
}

.bar-top a:hover {
  color: white;
}

#top-notice {
  height: 0px;
}

@media only screen and (max-width: 708px) {
  #top-notice {
    display: none;
  }
}

#top-notice a {
  color: white;
  display: block;
  font-size: 12px;
  padding: 6px;
}

#top-notice.toggled-search-bar {
  z-index: 100001;
  position: relative;
}

#bar-search {
  position: absolute;
  background-color: white;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  transition: 1s;
}

@media only screen and (max-width: 708px) {
  #bar-search {
    top: 48px;
  }
}

#bar-search #search-keyword {
  background-color: black;
  color: white;
  display: none;
}

#bar-search #bar-recommand-tags {
  width: 100%;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  display: none;
}

@media only screen and (max-width: 708px) {
  #bar-search #bar-recommand-tags {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  }
}

#bar-search #bar-recommand-tags #recommand-tags-label {
  padding: 13px;
}

#bar-search #bar-recommand-tags #main-tag-list {
  overflow: hidden;
  height: 100%;
  float: left;
  padding: 0;
  visibility: hidden;
}

#bar-search #bar-recommand-tags #main-tag-list ul {
  list-style: none;
  display: block;
  float: left;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  padding: 1px 0px;
  padding-top: 7px;
}

#bar-search #bar-recommand-tags #main-tag-list ul li {
  display: inline-block;
  /* changed */
  text-align: center;
  font-size: 15px;
}

#bar-search #bar-recommand-tags #main-tag-list ul li a {
  padding: 6px 10px;
  width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}

#bar-search #bar-recommand-tags #main-tag-list ul li a:hover {
  color: white;
}

#bar-search.toggled {
  position: absolute;
  background-color: black;
  border-bottom: 1px solid black;
  width: 100%;
  transition: 1s;
  z-index: 100001;
}

@media only screen and (max-width: 708px) {
  #bar-search.toggled {
    top: 48px;
  }
}

#bar-search.toggled #search-keyword {
  background-color: transparent !important;
  color: white;
  display: block;
}

#bar-search.toggled #bar-recommand-tags {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  color: white;
  display: block;
}

#bar-search.toggled #bar-recommand-tags #main-tag-list a {
  margin: 0 10px;
  display: inline-block;
  color: white;
}

#header {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 990;
}

@media only screen and (max-width: 708px) {
  #header {
    top: 0px;
  }
}

#header .header-center {
  text-align: center;
}

#header .header-center .header-logo {
  font-size: 20px;
}

#header .header-center .header-desc {
  font-size: 20px;
}

#header #edition_code {
  margin-top: 7px;
  padding: 1px 5px;
  font-size: 10px;
  cursor: pointer;
  color: #ccc;
  border: 1px solid #ccc;
}

#header .wide {
  box-sizing: border-box;
  border-bottom: 1px solid #e5e5e5;
  height: 80px;
}

@media only screen and (max-width: 708px) {
  #header .wide {
    display: none;
  }
}

#header .wide .header-left-container {
  width: 230px;
  overflow: hidden;
}

#header .wide #wide-header-logo {
  background-size: contain;
  width: 198px;
  height: 49px;
  display: inline-block;
  float: left;
  background-repeat: no-repeat;
  margin-top: 15px;
  position: relative;
  -ms-interpolation-mode: bicubic;
}

#header .wide .logoBox a {
  text-align: center;
  height: 80px;
  display: block;
}

#header .wide .logoBox a:hover {
  color: black;
}

#header .wide .header-btn a {
  color: black;
}

#header .wide #btn-master-category {
  float: left;
  text-align: center;
  font-size: 25px;
  margin-right: 10px;
  margin-left: 20px;
  height: 65px;
}

#header .wide #btn-master-category .category {
  position: absolute;
  margin-top: 0px;
}

#header .wide #btn-master-category .category .dropdown-menu {
  width: 180px;
  background-color: #333333;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
  filter: alpha(opacity=90);
  -moz-opacity: 0.90;
  -khtml-opacity: 0.90;
  opacity: 0.90;
}

#header .wide #btn-master-category .category .dropdown-menu li a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}

#header .wide #btn-master-category .category .dropdown-menu .dropdown-submenu {
  position: relative;
}

#header .wide #btn-master-category .category .dropdown-menu .dropdown-submenu .dropdown-menu {
  top: 0;
  left: 177px;
  margin-top: -1px;
}

#header .wide #btn-master-category .category .dropdown-menu .dropdown-submenu .dropdown-menu li a {
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}

#header .wide #btn-master-category .category .dropdown-menu .dropdown-submenu .dropdown-menu::before {
  border: 0px;
}

#header .wide #btn-master-category .category .dropdown-menu .dropdown-submenu:hover > .dropdown-menu {
  display: block;
  margin-top: 0;
}

#header .wide #btn-master-category .category .round-semi li:first-child a {
  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

#header .wide #btn-master-category .category .round-semi li:last-child a {
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

#header .wide #btn-master-category .category .dropdown-menu::before {
  /*
					position: absolute;
				    top: -7px;
				    left: 80px;
				    display: inline-block;
				    border-right: 7px solid transparent;
				    border-bottom: 7px solid #333;
				    border-left: 7px solid transparent;
				    content: '';
				    */
}

#header .wide #btn-master-category .category .dropdown:hover > .dropdown-menu {
  display: block;
  margin-top: 0;
}

#header .wide #btn-master-category .category .dropdown-menu a {
  color: white;
  padding: 13px 20px;
}

#header .wide #btn-master-category .btn-navi {
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

#header .wide #btn-master-category .btn-navi .dropdown-menu {
  width: 180px;
}

#header .wide #btn-master-category .btn-navi .navi-sub {
  display: none;
  background-color: white;
}

#header .wide #btn-master-category .btn-navi .navi-sub ul li {
  float: left;
  padding: 10px;
  cursor: pointer;
}

#header .wide #btn-master-category .btn-navi .navi-sub ul li a {
  color: #5c5c5c;
}

#header .wide #btn-master-category .btn-navi .navi-sub ul li a:hover {
  color: black;
}

#header .wide .header-wide-userbox .me {
  color: white;
  margin-right: 10px;
}

#header .wide .header-wide-userbox a {
  padding: 10px;
  display: block;
}

#header .wide .header-wide-userbox .dropdown-menu > li > a:hover {
  background-color: #3476b7 !important;
}

#header .wide .header-wide-userbox > a {
  text-align: center;
  font-size: 18px;
  display: block;
  color: white;
}

#header .wide .header-right-container {
  -webkit-transition: margin-left .3s ease-in-out;
  -o-transition: margin-left .3s ease-in-out;
  transition: margin-left .3s ease-in-out;
}

#header .wide .header-right-container > ul > li {
  position: relative;
  font-size: 19px;
  cursor: pointer;
}

#header .wide .header-right-container #header-wide-left-btn {
  font-size: 20px;
  padding: 9px 18px;
  font-family: xeicon;
}

#header .wide .header-right-container #header-wide-right-btn {
  font-size: 20px;
  padding: 9px 18px;
  font-family: xeicon;
}

#header .wide .utils {
  position: absolute;
  right: 39px;
  top: 0px;
  z-index: 99;
}

#header .wide .utils i {
  font-size: 25px;
  cursor: pointer;
}

#header .wide .utils #btn-show-login {
  padding-bottom: 15px;
}

#header .wide .utils #btn-show-login .dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 0px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

#header .wide .utils #btn-show-login .dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 1px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: '';
}

#header .wide .utils .header-profile-image {
  height: 30px;
  width: 30px;
  background-size: cover;
  background-position: center;
}

#header .wide .utils .header-profile-username {
  margin-top: 5px;
}

#header .narrow {
  height: 48px;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 300px;
  display: none;
  /*
		#btn-left {
		    left: 5px;
		    top: 0px;
		    a{
				color:#484848;    
		    }
		    
		}
		#btn-right {
		    right: 0px;
		    top: 0px;
		    a{
				color:#484848;    
		    }
		}
		*/
}

@media only screen and (max-width: 708px) {
  #header .narrow {
    display: block;
  }
}

#header .narrow .header-profile-image {
  height: 30px;
  width: 30px;
  background-size: cover;
  background-position: center;
}

#header .narrow #narrow-header-logo {
  background-size: contain;
  width: 145px;
  height: 32px;
  display: inline-block;
  margin: 0px auto 0;
  background-repeat: no-repeat;
  position: relative;
  margin-top: 9px;
}

#header .narrow .header-btn {
  position: absolute;
}

#header .narrow .header-btn > a {
  display: block;
  font-size: 23px;
  padding: 5px 16px;
  cursor: pointer;
}

#header .narrow .header-btn .btn-show-login > a {
  font-size: 18px;
}

#header .narrow .btnSidebar {
  position: absolute;
  padding: 11px 17px;
  cursor: pointer;
}

#header .narrow .btnSidebar i {
  font-size: 17px;
  color: white;
}

#header .narrow #btn-left {
  margin-top: 5px;
}

#header .narrow .logoBox {
  padding-top: 13px;
  /* display: inline-block; */
  text-align: center;
}

#header .narrow .logoBox a {
  color: white;
}

#header .narrow .btn-login a {
  text-align: center;
  padding: 10px;
  font-size: 16px;
  display: block;
  color: white;
}

#header .narrow .btn-show-login {
  color: white;
}

#header .narrow .btn-show-login i {
  margin-top: 5px;
}

#header .narrow .utils {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 99;
  padding: 7px 16px;
  color: #484848;
}

#header .narrow .utils > a {
  padding: 0px;
}

#header .narrow .utils i {
  font-size: 25px;
  cursor: pointer;
}

#header .narrow .utils #btn-show-login {
  padding-bottom: 15px;
}

#header .narrow .utils #btn-show-login .dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 0px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

#header .narrow .utils #btn-show-login .dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 1px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #ffffff;
  border-left: 6px solid transparent;
  content: '';
}

#header.toggled-search-bar {
  z-index: 100001;
}

#left-container {
  width: 23%;
  float: left;
  z-index: 10001;
  margin-top: 20px;
}

@media only screen and (max-width: 708px) {
  #left-container {
    display: none;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #left-container {
    display: none;
  }
}

#left-container .menuLst:first-child {
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
}

#left-container .menuLst {
  background-color: white;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}

#left-container .menuLst .boxTitle {
  font-size: 13px;
  padding: 12px 15px;
  background-color: white;
  display: block;
}

#left-container .menuLst .boxTitle:hover, #left-container .menuLst .boxTitle.selected, #left-container .menuLst .boxTitle.activated {
  background-color: #474e59;
  color: white;
}

#left-container .menuLst .subLst {
  display: none;
  background-color: #f0f2f5;
  border-bottom: 1px solid #e6e6e6;
}

#left-container .menuLst .subLst.selected {
  display: block;
}

#left-container .menuLst ul li a {
  font-size: 14px;
  padding: 15px;
  display: block;
}

#left-container .menuLst ul li a:hover {
  color: #005baa;
}

#left-container .menuLst ul li a.selected {
  color: #005baa;
  font-weight: bold;
}

#left-sidebar {
  width: 230px;
  height: 100%;
  top: 0;
  position: fixed;
  background-color: #222d32;
  z-index: 10001;
  overflow: hidden;
  left: -230px;
  font-size: 12px;
  -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  /* .left-content */
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #left-sidebar {
    display: none;
  }
}

#left-sidebar .left-siderbar-header {
  height: 48px;
  width: 100%;
  padding: 11px;
}

#left-sidebar .left-siderbar-header #sidebar-header-logo {
  background-size: contain;
  width: 110px;
  height: 32px;
  display: inline-block;
  margin: 0px auto 0;
  background-repeat: no-repeat;
  position: relative;
}

#left-sidebar .left-content {
  overflow-y: auto;
}

#left-sidebar .left-content .navi .parentNavi {
  border-top: 1px solid #37414b;
  border-bottom: 1px solid #1f262d;
}

#left-sidebar .left-content .navi .parentNavi > a {
  color: #939393;
  padding: 10px;
  display: block;
  border-left: 3px solid transparent;
}

.no-touch #left-sidebar .left-content .navi .parentNavi > a:hover {
  color: white;
  background-color: #8d8d8d;
}

#left-sidebar .left-content .navi .parentNavi > a.selected {
  color: white;
}

#left-sidebar .left-content .navi .childNavi {
  background-color: #2c3b41;
  overflow: hidden;
  display: none;
}

#left-sidebar .left-content .navi .childNavi .dropdown-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 15px;
  padding: 3px 0px;
}

#left-sidebar .left-content .navi .childNavi a {
  padding: 10px;
  padding-left: 30px;
  display: block;
  color: #8b8b8b;
  cursor: pointer;
}

#left-sidebar .left-content .navi .childNavi a:hover {
  background-color: #222d32;
  color: white;
}

#left-sidebar .left-content .navi .childNavi a.selected {
  color: white;
  font-weight: bold;
}

#left-sidebar .left-content .navi .childNavi.selected {
  display: block;
}

#right-container {
  width: 250px;
  padding: 8px;
}

#right-sidebar {
  width: 230px;
  height: 100%;
  top: 0;
  position: fixed;
  background-color: white;
  z-index: 10001;
  overflow: hidden;
  right: -230px;
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #right-sidebar {
    display: none;
  }
}

#right-sidebar ul li a {
  display: block;
  padding: 15px;
}

#right-sidebar ul li a:hover {
  background-clor: #ccc;
}

#right-sidebar .divider {
  border-bottom: 1px solid #ccc;
}

#post-info {
  margin-top: 20px;
}

#post-info .top .post-title {
  font-size: 25px;
  float: left;
}

#post-info .top-sub {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
}

#post-info .top-sub .post-cnt-date {
  font-size: 14px;
  color: #808080;
  padding-top: 7px;
}

#post-info .top-sub .post-sparkbars {
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: 0px;
  background-color: #ccc;
}

#post-info .top-sub .post-sparkbars #post-sparkbar-1 {
  float: left;
  background-color: #5c89fe;
  height: 100%;
}

#post-info .top-sub .post-sparkbars #post-sparkbar-2 {
  float: right;
  background-color: #d55959;
  height: 100%;
}

#post-info .middle {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}

#post-info .middle .user-info {
  margin-left: 10px;
}

#post-info .middle .user-info .subscribe {
  padding: 4px 10px;
  margin-top: 8px;
  cursor: pointer;
  background-color: #e9e9e9;
  border: 1px solid #8d8d8d;
  font-size: 12px;
  width: 75px;
  text-align: center;
}

#post-info .bottom {
  padding-top: 8px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
}

#post-info .bottom #btn_like {
  margin-right: 10px;
  cursor: pointer;
}

#post-info .bottom #btn_dislike {
  cursor: pointer;
}

#post-info .bottom #post-license {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

#post-info .bottom .post-desc {
  font-size: 13px;
  overflow: hidden;
}

#post-info #bottom-sub #box-post-download {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

#post-info #bottom-sub #box-post-download .btn-download {
  display: block;
  border: 1px solid #dbdbdb;
  float: left;
  max-width: 48%;
  min-width: 20%;
  margin-right: 1%;
  background-color: white;
  margin-top: 8px;
}

#post-info #bottom-sub #box-post-download .btn-download:hover {
  background-color: #eaeaea;
}

#post-info #bottom-sub #box-post-download .btn-download .download-icon {
  padding: 8px 0%;
  border-right: 1px solid #dbdbdb;
  width: 26%;
  text-align: center;
}

#post-info #bottom-sub #box-post-download .btn-download .download-label {
  width: 72%;
  padding: 8px 0%;
  padding-left: 4px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

#post-info #bottom-sub #box-post-download:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

#post-info #bottom-sub .post-tags .post-tag {
  font-size: 12px;
  margin-right: 12px;
  display: block;
  float: left;
}

#post-info #bottom-sub .post-tags:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

#post-info #post-info-count {
  position: relative;
  right: 0px;
  bottom: 0px;
  height: 30px;
  font-size: 20px;
  margin-top: 0px;
}

#post-info #post-info-count .post-feeling {
  padding-left: 10px;
}

.post-share {
  padding: 8px;
  width: 270px;
}

.post-share .btn-share {
  color: white;
  font-size: 21px;
  width: 38px;
  height: 38px;
  text-align: center;
  vertical-align: middle;
  display: block;
  float: left;
  cursor: pointer;
  margin-right: 4px;
}

.post-share .btn-share i {
  padding-top: 10px;
}

.post-share .btn-share img {
  width: 38px;
}

.post-share .post-share-label {
  font-size: 14px;
  color: #434343;
  font-weight: bold;
  padding: 4px;
}

.post-share-link {
  border-top: 1px solid #e5e5e5;
  margin: 8px;
  padding-top: 10px;
}

.post-share-link input {
  border: 0px;
  border-bottom: 2px solid #e5e5e5;
  width: 100%;
  font-size: 12px;
  padding: 5px;
}

.post-share-link input:focus {
  outline: none;
}

.category-label {
  padding: 30px 10px 30px;
  font-size: 20px;
}

.postLst .item {
  margin: 1%;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  padding: 0px;
  float: left;
}

@media only screen and (max-width: 708px) {
  .postLst .item {
    width: 48%;
    margin: 1%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .postLst .item {
    width: 32%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .postLst .item {
    width: 23%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .postLst .item {
    width: 23%;
  }
}

@media only screen and (min-width: 1280px) {
  .postLst .item {
    width: 23%;
  }
}

.postLst .item .postTitle {
  font-size: 15px;
  text-align: center;
  padding: 4px;
}

.postLst .item .postContent {
  font-size: 12px;
  padding: 4px;
}

.postLst .item img {
  max-width: 100%;
}

.postLst .box-post-list {
  margin: 8px;
}

@media only screen and (max-width: 708px) {
  .postLst .box-post-list {
    width: 100%;
    margin: 0px;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .postLst .box-post-list {
    width: 250px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .postLst .box-post-list {
    width: 196px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .postLst .box-post-list {
    width: 196px;
  }
}

@media only screen and (min-width: 1280px) {
  .postLst .box-post-list {
    width: 196px;
  }
}

.postLst .box-post-list .previewImg {
  background-size: cover;
  height: 110px;
}

@media only screen and (max-width: 708px) {
  .postLst .box-post-list .previewImg {
    width: 100%;
    height: 200px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .postLst .box-post-list .previewImg {
    width: 250px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .postLst .box-post-list .previewImg {
    width: 196px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .postLst .box-post-list .previewImg {
    width: 196px;
  }
}

@media only screen and (min-width: 1280px) {
  .postLst .box-post-list .previewImg {
    width: 196px;
  }
}

.postLst .box-post-list {
  margin: 0px 1% 50px;
  cursor: pointer;
  padding: 0px;
  float: left;
  height: 224px;
  position: relative;
}

@media only screen and (max-width: 708px) {
  .postLst .box-post-list {
    width: 48%;
    margin: 1%;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .postLst .box-post-list {
    width: 48%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .postLst .box-post-list {
    width: 196px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .postLst .box-post-list {
    width: 196px;
  }
}

@media only screen and (min-width: 1280px) {
  .postLst .box-post-list {
    width: 196px;
  }
}

.postLst .box-post-list a:hover {
  color: black;
}

.postLst .box-post-list .post-list-cover {
  display: block;
  position: absolute;
  width: 100%;
  height: 205px;
}

.postLst .box-post-list .post-list-cover .post-list-cover-image {
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 140px;
  background-position: 50%;
}

.postLst .box-post-list .post-list-cover .post-list-title {
  position: absolute;
  font-size: 13px;
  top: 140px;
  /* left: 10px; */
  padding: 8px 0px;
  width: 100%;
  height: 43px;
  /*
			    padding-top:20px;
			    padding-bottom:24px;			    
			    */
  font-weight: bold;
  overflow: hidden;
}

.postLst .box-post-list .post-list-cover .post-list-desc {
  position: absolute;
  width: 100%;
  font-size: 12px;
  padding: 8px;
  top: 163px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}

.postLst .box-post-list .bookFirstPost {
  border-top: 1px solid #ccc;
}

.postLst .box-post-list .bookFirstPost ul li {
  padding: 3px;
  font-size: 12px;
}

.postLst .box-post-list .tags {
  position: absolute;
  bottom: 45px;
  width: 100%;
  padding: 4px;
  font-size: 12px;
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.postLst .box-post-list img {
  max-width: 100%;
}

.postLst .box-post-list .bottomItem {
  position: absolute;
  bottom: 0px;
  width: 100%;
  color: #888;
}

.postLst .box-post-list .bottomItem .user-profile-image {
  float: left;
}

.postLst .box-post-list .bottomItem .box-owner-name {
  margin-bottom: 2px;
}

.postLst .box-post-list .bottomItem .box-owner-name a {
  color: #888;
}

.postLst .box-post-list .bottomItem .user-profile-name {
  margin-top: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  overflow: hidden;
  float: right;
  width: 80%;
}

.postLst .box-post-list .bottomItem .box-meta-info {
  font-size: 11px;
}

.right-list {
  margin-top: 10px;
}

.right-list .item {
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  padding: 0px;
  float: left;
}

@media only screen and (max-width: 708px) {
  .right-list .item {
    width: 48%;
    margin: 1%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .right-list .item {
    width: 32%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .right-list .item {
    width: 23%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .right-list .item {
    width: 23%;
  }
}

@media only screen and (min-width: 1280px) {
  .right-list .item {
    width: 23%;
  }
}

.right-list .item .postTitle {
  font-size: 15px;
  text-align: center;
  padding: 4px;
}

.right-list .item .postContent {
  font-size: 12px;
  padding: 4px;
}

.right-list .item img {
  max-width: 100%;
}

.right-list .box-right-list {
  width: 100%;
  margin: 8px;
}

.right-list .box-right-list .previewImg {
  background-size: cover;
  height: 110px;
  width: 100%;
}

.right-list .box-right-list {
  margin: 0px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  padding: 0px;
  float: left;
  height: 250px;
  position: relative;
}

.right-list .box-right-list a:hover {
  color: black;
}

.right-list .box-right-list .post-list-cover {
  display: block;
  position: absolute;
  width: 100%;
  height: 178px;
}

.right-list .box-right-list .post-list-cover .post-list-cover-image {
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 140px;
  background-position: 50%;
}

.right-list .box-right-list .post-list-cover .post-list-title {
  position: absolute;
  font-size: 15px;
  top: 140px;
  /* left: 10px; */
  padding: 8px;
  width: 100%;
  overflow: hidden;
}

.right-list .box-right-list .post-list-cover .post-list-desc {
  position: absolute;
  width: 100%;
  font-size: 12px;
  padding: 8px;
  top: 166px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}

.right-list .box-right-list .bookFirstPost {
  border-top: 1px solid #ccc;
}

.right-list .box-right-list .bookFirstPost ul li {
  padding: 3px;
  font-size: 12px;
}

.right-list .box-right-list .tags {
  position: absolute;
  bottom: 45px;
  width: 100%;
  padding: 4px;
  font-size: 12px;
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.right-list .box-right-list img {
  max-width: 100%;
}

.right-list .box-right-list .bottomItem {
  position: absolute;
  border-top: 1px solid #dfdfdf;
  bottom: 0px;
  width: 100%;
}

.right-list .box-right-list .bottomItem .box-meta-info {
  font-size: 11px;
}

.post-list {
  margin-top: 10px;
}

.post-list .item {
  margin: 1%;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  padding: 0px;
  float: left;
}

.post-list .item .postTitle {
  font-size: 15px;
  text-align: center;
  padding: 4px;
}

.post-list .item .postContent {
  font-size: 12px;
  padding: 4px;
}

.post-list .item img {
  max-width: 100%;
}

.post-list .box-post-list {
  cursor: pointer;
  padding: 0px;
  height: 225px;
  position: relative;
  /*
		@include respond-to(res_lv1) { width:48%; margin:1%; }
		@include respond-to(res_lv2) { width:31%; }
		@include respond-to(res_lv3) { width:18%; }	
		@include respond-to(res_lv4){width:18%;}
		@include respond-to(res_lv5){width:18%;}
		*/
  margin: 8px;
}

.post-list .box-post-list .previewImg {
  background-size: cover;
  height: 110px;
}

@media only screen and (max-width: 708px) {
  .post-list .box-post-list .previewImg {
    width: 100%;
    height: 200px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .post-list .box-post-list .previewImg {
    width: 250px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .post-list .box-post-list .previewImg {
    width: 196px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .post-list .box-post-list .previewImg {
    width: 196px;
  }
}

@media only screen and (min-width: 1280px) {
  .post-list .box-post-list .previewImg {
    width: 196px;
  }
}

.post-list .box-post-list a:hover {
  color: black;
}

.post-list .box-post-list .post-list-cover {
  display: block;
  position: absolute;
  width: 100%;
  height: 178px;
}

.post-list .box-post-list .post-list-cover .post-list-cover-image {
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 140px;
  background-position: 50%;
}

.post-list .box-post-list .post-list-cover .post-list-title {
  position: absolute;
  font-size: 15px;
  top: 140px;
  /* left: 10px; */
  padding: 8px 0px;
  width: 100%;
  overflow: hidden;
  height: 58px;
}

.post-list .box-post-list .post-list-cover .post-list-desc {
  position: absolute;
  width: 100%;
  font-size: 12px;
  padding: 8px;
  top: 166px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}

.post-list .box-post-list .bookFirstPost {
  border-top: 1px solid #ccc;
}

.post-list .box-post-list .bookFirstPost ul li {
  padding: 3px;
  font-size: 12px;
}

.post-list .box-post-list .tags {
  position: absolute;
  bottom: 45px;
  width: 100%;
  padding: 4px;
  font-size: 12px;
  height: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.post-list .box-post-list img {
  max-width: 100%;
}

.post-list .box-post-list .bottomItem {
  position: absolute;
  bottom: 0px;
  width: 100%;
  color: #888;
}

.post-list .box-post-list .bottomItem a {
  color: #888;
}

.post-list .box-post-list .bottomItem .user-profile-image {
  float: left;
}

.post-list .box-post-list .bottomItem .user-profile-name {
  margin-top: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  overflow: hidden;
  float: right;
  width: 80%;
}

.post-list .box-post-list .bottomItem .box-meta-info {
  font-size: 11px;
}

.post-list .box-post-list.no-desc {
  height: 225px;
}

#video-wrapper {
  background-color: black;
  position: relative;
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #video-wrapper {
    width: 100%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #video-wrapper {
    width: 100%;
  }
}

@media only screen and (min-width: 1280px) {
  #video-wrapper {
    width: 100%;
  }
}

#video-wrapper .btn-mode-cimena, #video-wrapper .btn-close-fixed-video {
  cursor: pointer;
}

#video-wrapper #box-video {
  margin: 0 auto 0;
}

@media only screen and (max-width: 708px) {
  #video-wrapper #box-video {
    width: 100%;
    height: 200px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #video-wrapper #box-video {
    width: 100%;
    height: 300px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #video-wrapper #box-video {
    width: 100%;
    height: 400px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #video-wrapper #box-video {
    width: 993px;
    height: 400px;
  }
}

@media only screen and (min-width: 1280px) {
  #video-wrapper #box-video {
    width: 993px;
    height: 600px;
  }
}

#video-wrapper #box-video .fix-util-bar, #video-wrapper #box-video .fix-util-bar-hide {
  display: none;
  background-color: #484848;
  color: #ececec;
  font-size: 20px;
  padding-top: 3px;
  padding-bottom: 2px;
}

#video-wrapper #box-video .fix-util-bar-hide {
  padding-top: 0px;
}

#video-wrapper #box-video .box-video-player {
  height: 100%;
}

#video-wrapper #box-video .box-video-player .video-box {
  height: 100%;
}

#video-wrapper #box-video.mode-cinema {
  position: fixed;
  margin: 0 auto 0;
  z-index: 10001;
  width: 90%;
}

@media only screen and (max-width: 708px) {
  #video-wrapper #box-video.mode-cinema {
    width: 100%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #video-wrapper #box-video.mode-cinema {
    width: 100%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #video-wrapper #box-video.mode-cinema {
    width: 100%;
    height: 550px;
  }
  #video-wrapper #box-video.mode-cinema #commons_player {
    height: 550px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #video-wrapper #box-video.mode-cinema {
    width: 100%;
    height: 600px;
  }
  #video-wrapper #box-video.mode-cinema #commons_player {
    height: 600px;
  }
}

@media only screen and (min-width: 1280px) {
  #video-wrapper #box-video.mode-cinema {
    width: 100%;
    height: 600px;
  }
  #video-wrapper #box-video.mode-cinema #commons_player {
    height: 600px;
  }
}

#video-wrapper #box-video.mode-cinema .fix-util-bar {
  display: none;
}

#video-wrapper #box-video.mode-fixed {
  border: 1px solid #ccc;
}

@media only screen and (max-width: 708px) {
  #video-wrapper #box-video.mode-fixed {
    width: 320px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #video-wrapper #box-video.mode-fixed {
    width: 320px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #video-wrapper #box-video.mode-fixed {
    position: fixed;
    bottom: 100px;
    right: 0px;
    z-index: 9999;
    width: 300px;
    height: 200px;
  }
  #video-wrapper #box-video.mode-fixed #commons_player {
    height: 200px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #video-wrapper #box-video.mode-fixed {
    position: fixed;
    bottom: 100px;
    right: 0px;
    z-index: 9999;
    width: 300px;
    height: 200px;
  }
  #video-wrapper #box-video.mode-fixed #commons_player {
    height: 200px;
  }
}

@media only screen and (min-width: 1280px) {
  #video-wrapper #box-video.mode-fixed {
    position: fixed;
    bottom: 100px;
    right: 0px;
    z-index: 9999;
    width: 400px;
    height: 266px;
  }
  #video-wrapper #box-video.mode-fixed #commons_player {
    height: 266px;
  }
}

#video-wrapper #box-video.mode-fixed .fix-util-bar {
  display: block;
}

#video-wrapper #box-video.mode-fixed-hide {
  width: 35px;
}

#video-wrapper #box-video.mode-fixed-hide .video-player {
  display: none;
}

#video-wrapper #box-video.mode-fixed-hide .fix-util-bar {
  display: none;
}

#video-wrapper #box-video.mode-fixed-hide .fix-util-bar-hide {
  display: block;
}

#video-wrapper .share-box {
  position: absolute;
  right: 20px;
  top: 10px;
}

@media only screen and (max-width: 708px) {
  #video-wrapper .share-box {
    display: none;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #video-wrapper .share-box {
    display: none;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #video-wrapper .share-box {
    display: none;
  }
}

#video-wrapper .share-box ul li {
  color: #ccc;
  font-size: 40px;
  cursor: pointer;
}

#video-wrapper .share-box ul li:hover {
  color: white;
  font-size: 40px;
  cursor: pointer;
}

#video-wrapper .share-box ul li.likeit {
  font-size: 35px;
}

#video-wrapper .share-box ul li:hover.likeit {
  color: #2d91e8;
}

#video-wrapper .share-box ul #more-share ul li {
  color: #4d4d4d;
  padding: 8px;
  float: left;
  font-size: 29px;
  width: 49px;
  text-align: center;
  cursor: pointer;
}

#video-wrapper .share-box ul #more-share ul li:hover {
  color: black;
}

.box-comment {
  padding-bottom: 20px;
  padding: 8px 8px 20px;
}

.widget-area.blank {
  background: none repeat scroll 0 0 transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

body .no-padding {
  padding: 0;
}

.box-comment-text[contenteditable=true] {
  border-bottom: 1px solid #ccc;
  resize: none;
  padding: 4px;
  min-height: 30px;
}

.widget-area {
  background-color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.05);
  float: left;
  margin-top: 15px;
  padding: 25px 30px;
  position: relative;
  width: 100%;
}

.status-upload {
  background: none repeat scroll 0 0 #f5f5f5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  float: left;
  width: 100%;
}

.status-upload form {
  width: 100%;
}

.status-upload textarea {
  display: block;
  background: none repeat scroll 0 0 #fff;
  border: medium none;
  color: #777777;
  font-size: 14px;
  height: 79px;
  letter-spacing: 0.3px;
  padding: 20px;
  width: 100%;
  resize: none;
  outline: none;
}

.status-upload ul {
  float: left;
  list-style: none outside none;
  margin: 0;
  padding: 0 0 0 15px;
  width: auto;
}

.status-upload ul > li {
  float: left;
}

.status-upload ul > a {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  color: #777777;
  float: left;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  margin: 10px 0 10px 10px;
  text-align: center;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  width: 30px;
  cursor: pointer;
}

.status-upload ul > a:hover {
  background: none repeat scroll 0 0 #606060;
  color: #fff;
}

.status-upload button {
  border: medium none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.3px;
  padding: 6px 15px;
}

.dropdown > a > span.green:before {
  border-left-color: #2dcb73;
}

.status-upload #btn-add-comment {
  color: #fff;
  background-color: #008cba;
  border-color: #0079a1;
  padding: 0px 40px;
}

.status-upload #btn-add-comment:hover {
  color: #fff;
  background-color: #006687;
  border-color: #004b63;
}

.status-upload form button > i {
  margin-right: 7px;
}

.thumbnail {
  padding: 0px;
}

#post-comment {
  margin-top: 30px;
  padding: 15px;
}

#post-comment #box-input-comment {
  padding: 20px;
  background-color: #edeff2;
}

#post-comment #box-comments {
  margin-top: 20px;
}

#post-comment .comment-user-image {
  padding-left: 0px;
}

.comment-user-image {
  padding: 0px;
}

.panel {
  position: relative;
}

.panel > .panel-heading:after, .panel > .panel-heading:before {
  position: absolute;
  top: 11px;
  left: -16px;
  right: 100%;
  width: 0;
  height: 0;
  display: block;
  content: " ";
  border-color: transparent;
  border-style: solid solid outset;
  pointer-events: none;
}

.panel > .panel-heading:after {
  border-width: 7px;
  border-right-color: #f7f7f7;
  margin-top: 1px;
  margin-left: 2px;
}

.panel > .panel-heading:before {
  border-right-color: #ddd;
  border-width: 8px;
}

.box-comment {
  margin-left: 30px;
}

.box-comment.level-0 {
  margin-left: 0px;
}

.btn-comment {
  cursor: pointer;
}

.page-navi {
  padding: 4px 0px;
  border-bottom: 1px solid #d2d2d2;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  font-size: 18px;
  border-bottom: 1px solid #e5e5e5;
}

.page-navi a, .page-navi span {
  font-size: 14px;
}

.page-info .page-info-name {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.page-info .page-info-desc {
  font-size: 18px;
  font-size: 15px;
}

.box-divider {
  color: #a9a9a9;
  padding: 0px 10px;
  font-size: 12px;
}

.box-created {
  color: #a9a9a9;
  font-weight: 100;
  font-size: 12px;
}

.type-a {
  border-top: 1px solid #e6e6e6;
}

.type-a .box-divider {
  color: #a9a9a9;
  padding: 0px 10px;
  font-size: 12px;
}

.type-a .box-created {
  color: #a9a9a9;
  font-weight: 100;
  font-size: 12px;
}

.type-a .btn-course-more {
  display: block;
  border: 1px solid #cccccc;
  font-size: 11px;
  padding: 7px 11px;
  color: #5d5d5d;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}

.type-a .btn-course-more:hover {
  color: black;
}

.type-a .postLst .box-post-list .post-list-cover .post-list-cover-image {
  -webkit-box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0px 50px rgba(0, 0, 0, 0.1);
}

.type-b .postLst .box-post-list {
  margin: 1% 0px;
  width: 33%;
  padding-left: 1%;
  padding-right: 1%;
  height: auto !important;
}

.type-b .postLst .box-post-list .post-course-title {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 50px;
  font-weight: bold;
  font-size: 18px;
}

.type-b .postLst .box-post-list .post-list-title {
  margin-bottom: 30px;
  margin-top: 50px;
  font-weight: bold;
  font-size: 15px;
}

.type-b .postLst .box-post-list .box-divider {
  color: #a9a9a9;
  padding: 0px 10px;
  font-size: 12px;
}

.type-b .postLst .box-post-list .box-created {
  color: #a9a9a9;
  font-weight: 100;
  font-size: 12px;
}

.type-b .postLst .box-post-list .post-list-cover {
  position: relative;
  height: 272px;
}

.type-b .postLst .box-post-list .post-list-cover .post-list-cover-image {
  height: 180px;
}

.type-b .postLst .box-post-list-1 {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

#block-main-bottom {
  padding-bottom: 55px;
  padding-top: 60px;
  border-top: 1px solid #e6e6e6;
}

#block-bottom-links .box-link {
  width: 20%;
}

#block-bottom-links .box-link .link-label {
  font-size: 19px;
  margin-bottom: 19px;
}

#block-bottom-links .box-link .link-list a {
  display: block;
  font-size: 13px;
  color: #999999;
  margin-bottom: 16px;
}

.type-c {
  height: 210px;
  min-height: 210px;
  border-top: 1px solid #e6e6e6;
  background-color: white;
}

.type-c .main-title {
  font-size: 16px;
  text-align: left;
  font-weight: bold;
  padding: 25px 0px 20px 0px;
}

.type-c .box-main-bbs-list {
  height: 120px;
  overflow: hidden;
}

@media only screen and (max-width: 708px) {
  .type-c .box-main-bbs-list {
    width: 48%;
    margin: 1%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .type-c .box-main-bbs-list {
    width: 32%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .type-c .box-main-bbs-list {
    width: 23%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .type-c .box-main-bbs-list {
    width: 23%;
  }
}

@media only screen and (min-width: 1280px) {
  .type-c .box-main-bbs-list {
    width: 23%;
  }
}

.type-c .box-main-bbs-list .bbs-name a {
  font-weight: bold;
  font-size: 15px;
}

.block-user-main {
  min-height: 130px;
  width: 100%;
  overflow: hidden;
  background-color: white;
  border: 1px solid #dadddf;
}

.block-user-main .userInfo {
  position: static !important;
}

.block-user-main .userCover {
  position: relative;
  background-image: url("/_src/common/pageDefaultBackCover.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 130px;
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .block-user-main .userCover {
    height: 100px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .block-user-main .userCover {
    height: 100px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .block-user-main .userCover {
    height: 100px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .block-user-main .userInfo {
    position: absolute;
    bottom: 25px;
    width: 100%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .block-user-main .userInfo {
    position: absolute;
    bottom: 25px;
    width: 100%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .block-user-main .userInfo {
    position: absolute;
    bottom: 25px;
    width: 100%;
  }
}

@media only screen and (min-width: 1280px) {
  .block-user-main .userInfo {
    position: absolute;
    bottom: 15px;
    width: 100%;
  }
}

.block-user-main .userInfo .userImage {
  width: 40%;
}

@media only screen and (max-width: 708px) {
  .block-user-main .userInfo .userImage {
    width: 100%;
  }
}

.block-user-main .userInfo .userImage .img-circle {
  border: 2px solid #e8e8e8;
  width: 80px;
  height: 80px;
  margin-left: 40px;
  margin-top: 24px;
  display: block;
  background-position: center;
  background-size: cover;
}

@media only screen and (max-width: 708px) {
  .block-user-main .userInfo .userImage .img-circle {
    width: 47px;
    margin: 20px auto 0px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .block-user-main .userInfo .userImage .img-circle {
    width: 55px;
    margin-left: 10px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .block-user-main .userInfo .userImage .img-circle {
    width: 55px;
    margin-left: 20px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .block-user-main .userInfo .userImage .img-circle {
    width: 65px;
    margin-left: 30px;
  }
}

@media only screen and (min-width: 1280px) {
  .block-user-main .userInfo .userImage .img-circle {
    width: 80px;
  }
}

.block-user-main .userInfo .userInfo-detail {
  width: 59%;
  float: left;
  margin-top: 19px;
  padding-left: 22px;
}

@media only screen and (max-width: 708px) {
  .block-user-main .userInfo .userInfo-detail {
    margin-top: 4px;
    padding: 0px;
    text-align: center;
    width: 100%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .block-user-main .userInfo .userInfo-detail {
    padding-left: 10px;
  }
}

.block-user-main .userInfo .userInfo-detail .userName {
  text-align: inherit;
  margin-top: 3px;
  font-weight: 400;
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .block-user-main .userInfo .userInfo-detail .userName {
    font-size: 14px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .block-user-main .userInfo .userInfo-detail .userName {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .block-user-main .userInfo .userInfo-detail .userName {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1280px) {
  .block-user-main .userInfo .userInfo-detail .userName {
    font-size: 20px;
  }
}

@media only screen and (max-width: 708px) {
  .block-user-main .userInfo .user-relationship {
    width: 80%;
    margin: 8px auto 0;
  }
}

.block-user-main .userInfo .user-narrow-btns {
  border-top: 1px solid #e9e9e9;
  margin-top: 8px;
}

.block-user-main .userInfo .user-narrow-btns ul li {
  float: left;
  width: 25%;
}

.block-user-main .userInfo .user-narrow-btns ul li a {
  display: block;
  padding: 8px;
}

.block-user-main .userInfo .user-narrow-btns ul li a .statistic-label {
  text-align: center;
  font-size: 13px;
}

.block-user-main .userInfo .user-narrow-btns ul li a .statistic-count {
  font-size: 11px;
  text-align: center;
}

.block-user-main .userInfo .user-narrow-btns ul li a:hover {
  background-color: #f5f5f5;
}

.block-user-main .user-relationship {
  font-size: 12px;
  width: 100%;
  margin-top: 9px;
}

.block-user-main .user-relationship a {
  width: 50%;
  display: block;
  color: #808080;
}

.block-user-main .user-relationship a:hover {
  color: black;
}

.block-user-main .label-user-lv {
  margin-bottom: 6px;
  font-size: 14px;
}

.block-user-main .btn-statistics {
  font-size: 12px;
}

.block-user-main .btn-statistics a {
  color: #989898;
}

.block-user-main .btn-statistics a:hover {
  color: #606060;
}

.block-user-main .bar-user-exp {
  height: 10px;
  background-color: #e6e6e6;
  width: 100%;
  margin-bottom: 6px;
  position: relative;
}

.block-user-main .bar-user-exp .bar-user-exp-current {
  width: 10%;
  height: 100%;
  background-color: #5275d0;
  float: left;
}

.block-user-main .bar-user-exp .bar-user-exp-remaining {
  width: 10%;
  height: 100%;
  float: left;
}

.block-user-main .label-remaining-exp {
  font-size: 12px;
}

.block-user-main .userBlock-top {
  height: 130px;
  position: relative;
  float: left;
  width: 25%;
}

@media only screen and (max-width: 708px) {
  .block-user-main .userBlock-top {
    width: 30%;
  }
}

.block-user-main .userBlock-top .userBorder {
  width: 100%;
  height: 101px;
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
}

.block-user-main .userBlock-bottom {
  width: 75%;
  float: left;
}

@media only screen and (max-width: 708px) {
  .block-user-main .userBlock-bottom {
    width: 70%;
  }
}

.block-user-main .userBlock-bottom .user-status {
  height: 93px;
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
}

.block-user-main .userBlock-bottom .user-status .user-apoint, .block-user-main .userBlock-bottom .user-status .user-lv {
  padding: 0px 10px;
  padding-top: 17px;
}

.block-user-main .userBlock-bottom .user-statistics-overview {
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  float: left;
  width: 80%;
}

@media only screen and (max-width: 708px) {
  .block-user-main .userBlock-bottom .user-statistics-overview {
    width: 100%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .block-user-main .userBlock-bottom .user-statistics-overview {
    width: 78%;
  }
}

.block-user-main .userBlock-bottom .user-statistics-overview ul li {
  float: left;
  width: 25%;
}

@media only screen and (max-width: 708px) {
  .block-user-main .userBlock-bottom .user-statistics-overview ul li {
    width: 50%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .block-user-main .userBlock-bottom .user-statistics-overview ul li {
    width: 33.3%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .block-user-main .userBlock-bottom .user-statistics-overview ul li {
    width: 25%;
  }
}

.block-user-main .userBlock-bottom .user-statistics-overview ul li a {
  height: 130px;
  background-color: #f0f2f5;
  display: block;
  padding: 30px;
  border-left: 1px solid #dadddf;
}

@media only screen and (max-width: 708px) {
  .block-user-main .userBlock-bottom .user-statistics-overview ul li a {
    padding: 29px 18px;
  }
}

.block-user-main .userBlock-bottom .user-statistics-overview ul li a:hover {
  background-color: #e9e9e9;
}

.block-user-main .userBlock-bottom .user-statistics-overview ul li:after {
  clear: both;
  content: " ";
  display: block;
}

.block-user-main .userBlock-bottom .user-statistics-overview ul li .statistic-label {
  color: #666666;
  font-size: 14px;
  text-align: center;
}

.block-user-main .userBlock-bottom .user-statistics-overview ul li .statistic-count {
  margin-bottom: 10px;
  font-size: 27px;
  text-align: center;
}

.block-user-main .userBlock-bottom .user-statistics-overview .btn-mycourses {
  display: block;
  padding: 18px;
  font-size: 17px;
  text-decoration: none;
}

.block-user-main .userBlock-bottom .user-statistics-overview .btn-mycourses:hover {
  text-decoration: none;
  background-color: #f7f7f7;
}

.block-user-main .userBlock-bottom .userBlock-tail {
  width: 20%;
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .block-user-main .userBlock-bottom .userBlock-tail {
    width: 22%;
  }
}

.block-user-main .userBlock-bottom .userBlock-tail .btn-tails {
  color: white;
  display: block;
  text-align: center;
  padding: 21px;
  font-size: 17px;
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .block-user-main .userBlock-bottom .userBlock-tail .btn-tails {
    padding: 25px 21px;
    font-size: 12px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .block-user-main .userBlock-bottom .userBlock-tail .btn-tails {
    padding: 23px;
    font-size: 13px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .block-user-main .userBlock-bottom .userBlock-tail .btn-tails {
    font-size: 16px;
    padding: 21px;
  }
}

.block-user-main .userBlock-bottom .userBlock-tail .btn-main-block-add-content {
  background-color: #0aa958;
}

.block-user-main .userBlock-bottom .userBlock-tail .btn-main-block-channel-manage {
  background-color: #2f4359;
}

.btn-choose {
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  padding: 4px 15px;
}

.btn-choose.selected {
  font-weight: bold;
}

.btn-feeling {
  color: #8f8f8f;
  cursor: pointer;
  width: 50%;
  font-size: 16px;
  text-align: left;
}

.btn-feeling#btn-feeling-1:hover, .btn-feeling#btn-feeling-1.activated {
  color: #6262c5;
}

.btn-feeling#btn-feeling-2:hover, .btn-feeling#btn-feeling-2.activated {
  color: #e94b4b;
}

#btn-post-share {
  cursor: pointer;
  color: #888888;
}

#btn-post-share i {
  font-size: 18px;
  margin-right: 4px;
}

#btn-post-share:hover {
  color: #232323;
}

.btn-report {
  cursor: pointer;
  font-size: 22px;
}

.btn-report i {
  color: #888888;
}

.btn-report:hover i {
  color: #d25656;
}

.btn-require-auth {
  cursor: pointer;
}

.btn-require-auth i {
  color: #888888;
}

.btn-more {
  cursor: pointer;
  width: 120px;
  text-align: center;
  margin: 0 auto 0;
}

.btn-more .btn-more-page {
  font-size: 20px;
}

.btn-more .btn-more-angle {
  font-size: 25px;
  line-height: 0px;
  margin-top: 5px;
}

.btn-more:hover {
  cursor: pointer;
}

#btn-tabs {
  border-bottom: 1px solid #ccc;
}

#btn-tabs .selected {
  color: white;
}

.tab-panel {
  display: none;
}

.tab-panel.selected {
  display: block;
}

#panel-file-upload {
  margin-top: 25px;
}

#panel-file-upload #uploadZone {
  min-height: 200px;
  min-width: 320px;
}

#panel-file-upload .dropzone {
  border: 2px dashed #0087F7;
  border-radius: 5px;
  background: white;
}

.btn-default {
  background-color: white;
}

.btn-default:hover, .btn-default:active, .btn-default:focus {
  background-color: #f0f2f5;
}

.open .btn-default.dropdown-toggle {
  background-color: #f0f2f5;
}

.open .btn-default.dropdown-toggle:hover, .open .btn-default.dropdown-toggle:focus {
  background-color: #f0f2f5;
}

.list li {
  float: left;
}

/*
	$responsive-lv1: 708px; // No Padding
	$responsive-lv2: 816px; // padding: 0 30px 57px;
	$responsive-lv3: 1024px; // padding:0 30px 57px;
	$responsive-lv4: 1280px; // padding: 0 40px 0px;
*/
.container-2-row-main {
  float: right;
  margin-top: 20px;
  min-height: 100px;
}

@media only screen and (max-width: 708px) {
  .container-2-row-main {
    width: 98%;
    margin: 1% auto;
    float: inherit;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .container-2-row-main {
    width: 64%;
    margin-left: 1%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .container-2-row-main {
    width: 69%;
    margin-left: 1%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .container-2-row-main {
    width: 74%;
    margin-left: 1%;
  }
}

@media only screen and (min-width: 1280px) {
  .container-2-row-main {
    width: 74%;
    margin-left: 1%;
  }
}

#block-main-bottom .bottom-main-label {
  padding-top: 60px;
  font-size: 20px;
}

#block-main-bottom .bottom-label {
  font-size: 19px;
  margin-bottom: 19px;
}

#block-main-bottom .botton-box-category {
  padding-top: 60px;
}

#block-main-bottom .bottom-category-list {
  overflow: hidden;
  height: 91px;
}

#block-main-bottom .bottom-category-list li a {
  float: left;
  width: 119px;
  text-align: center;
  padding: 34px 0px;
  display: block;
  border: 1px solid #e6e6e6;
  border-left: 0px;
}

#block-main-bottom .bottom-category-list li a:hover {
  background-color: #91bdff;
}

#block-main-bottom .bottom-category-list li:first-child a {
  border-left: 1px solid #e6e6e6;
}

#block-main-bottom .bottom-category-list li:last-child a {
  border-left: 0px;
}

#block-main-bottom .block-bottom-links {
  margin-top: 60px;
}

.box-error {
  text-align: center;
  padding: 10% 8px;
  font-size: 20px;
}

.box-page-error {
  text-align: center;
  font-size: 16px;
  border: 1px solid #d9d9db;
  background-color: white;
  padding: 70px;
  width: 100%;
}

.box-page-error.margin {
  margin: 10% 8px;
}

.box-page-error .warning-icon {
  font-size: 35px;
  color: #e66f85;
}

.box-page-error .warning-text {
  color: #4d4d4d;
}

.box-loading {
  font-size: 25px;
  text-align: center;
  padding: 20px;
}

.box-top-container hr {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 0;
  border-top: 1px solid black;
}

.box-top-container .page-label {
  font-size: 25px;
}

.box-top-container .page-info {
  margin-top: 40px;
  background-color: #474e59;
  height: 310px;
  position: relative;
}

@media only screen and (max-width: 708px) {
  .box-top-container .page-info {
    height: inherit;
  }
}

.box-top-container .page-info .page-primaryImage {
  height: 310px;
  background-size: cover;
  background-position: center;
  width: 60%;
}

@media only screen and (max-width: 708px) {
  .box-top-container .page-info .page-primaryImage {
    width: 100%;
    margin: 1%;
  }
}

.box-top-container .page-info .page-primaryImage .count-post {
  height: 54px;
  width: 54px;
  text-align: center;
  padding: 15px 10px;
  color: white;
  background-color: #4aafea;
}

.box-top-container .page-info .page-description {
  font-size: 14px;
  padding: 10px;
  text-align: left;
  width: 40%;
}

@media only screen and (max-width: 708px) {
  .box-top-container .page-info .page-description {
    width: 100%;
    margin: 1%;
  }
}

.box-top-container .page-info .page-info-category {
  padding: 20px;
  color: white;
  font-size: 13px;
}

.box-top-container .page-info .page-info-category ul li {
  margin-bottom: 10px;
}

.box-top-container .page-info .page-info-category ul li a {
  color: #b6b8bb;
  font-size: 12px;
}

.box-middle-container {
  padding-bottom: 10px;
  overflow: hidden;
}

.box-middle-container .page-info-desc {
  padding-top: 20px;
}

.box-list table thead {
  background-color: #d6d6d6;
}

.box-list table thead tr {
  height: 51px;
}

.box-list table thead tr th {
  vertical-align: middle;
  text-align: center;
}

.box-list table tbody tr td {
  padding: 15px;
  text-align: center;
  vertical-align: middle;
  font-size: 15px;
  cursor: pointer;
}

.box-list table tbody tr td .post-preview-content {
  margin-left: 20px;
  margin-top: 25px;
  vertical-align: middle;
}

.box-list table tbody tr td .post-primaryImage {
  width: 128px;
  height: 80px;
  background-position: center;
  background-size: cover;
  position: relative;
}

.box-list table tbody tr td .post-primaryImage .post-duration {
  background-color: black;
  color: white;
  position: absolute;
  right: 0px;
  bottom: 0px;
  padding: 5px;
  font-size: 13px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
  filter: alpha(opacity=75);
  -moz-opacity: 0.75;
  -khtml-opacity: 0.75;
  opacity: 0.75;
}

.box-list table tbody tr:hover {
  background-color: #e9e9e9;
}

.box-list .box-course-list .list-cover {
  display: block;
  padding-top: 20px;
  border-bottom: 1px solid #ccc;
  height: 165px;
  overflow: hidden;
}

.box-list .box-course-list .list-cover .list-cover-image {
  width: 25%;
  height: 126px;
  background-size: cover;
  margin: 10px;
  margin-top: 0px;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.box-list .box-course-list .list-cover .list-cover-image .list-cover-posts-count {
  position: absolute;
  height: 100%;
  width: 30%;
  background-color: black;
  color: white;
  text-align: center;
  right: 0;
  padding-top: 42px;
  opacity: 0.70;
  filter: alpha(opacity=70);
}

.box-list .box-course-list .list-cover .list-cover-image .list-cover-image-background {
  width: 100%;
  height: 126px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.box-list .box-course-list .list-cover .list-info {
  width: 70%;
}

@media only screen and (max-width: 708px) {
  .box-list .box-course-list .list-cover .list-info {
    width: 67%;
  }
}

.box-list .box-course-list .list-cover .list-info .list-title {
  font-size: 20px;
  font-weight: bold;
}

.box-list .box-course-list .list-cover .list-info .list-title a {
  color: #545454;
}

.box-list .box-course-list .list-cover .list-info .list-title a:hover {
  color: black;
}

.box-list .box-course-list .list-cover .list-info .list-meta-info, .box-list .box-course-list .list-cover .list-info .box-created {
  margin-bottom: 20px;
  font-size: 1.3rem;
  color: #847d7d;
}

.box-list .box-course-list .list-cover .list-info .list-desc {
  max-height: 37px;
  overflow: hidden;
  font-size: 1.3rem;
  color: #847d7d;
}

.box-list .box-course-list .list-cover:hover {
  background-color: #cfcfcf;
}

.box-list.box-post .box-course-list .list-cover {
  display: block;
  padding-top: 20px;
  border-bottom: 1px solid #ccc;
  height: 117px;
  overflow: hidden;
}

.box-list.box-post .box-course-list .list-cover .list-cover-image {
  width: 15%;
  height: 80px;
  background-size: cover;
  margin: 10px;
  margin-top: 0px;
  background-repeat: no-repeat;
  background-position: center center;
}

.box-list.box-post .box-course-list .list-cover .list-info {
  width: 80%;
}

@media only screen and (max-width: 708px) {
  .box-list.box-post .box-course-list .list-cover .list-info {
    width: 67%;
  }
}

.box-list.box-post .box-course-list .list-cover .list-info .list-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.box-list.box-post .box-course-list .list-cover .list-info .box-created {
  color: #3c3c3c;
}

.box-list.box-post .box-course-list .list-cover:hover {
  background-color: #cfcfcf;
}

.box-list .box-course-list-left {
  float: left;
  width: 20%;
  height: 176px;
  overflow: hidden;
}

@media only screen and (max-width: 708px) {
  .box-list .box-course-list-left {
    width: 50%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .box-list .box-course-list-left {
    width: 33%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .box-list .box-course-list-left {
    width: 33%;
  }
}

.box-list .box-course-list-left .list-cover {
  display: block;
  padding-top: 10px;
  overflow: hidden;
}

.box-list .box-course-list-left .list-cover .list-cover-image {
  height: 126px;
  background-size: cover;
  margin: 10px;
  margin-top: 0px;
  background-repeat: no-repeat;
  background-position: center center;
}

.box-list .box-course-list-left .list-cover .list-info {
  width: 70%;
}

@media only screen and (max-width: 708px) {
  .box-list .box-course-list-left .list-cover .list-info {
    width: 67%;
  }
}

.box-list .box-course-list-left .list-cover .list-info .list-title {
  padding: 0px 12px;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 20px;
}

.box-list .box-course-list-left .list-cover .list-info .box-created {
  color: #3c3c3c;
}

.box-list .box-course-list-left .list-cover:hover {
  background-color: #cfcfcf;
}

.box-list .list-label {
  height: 25px;
}

.box-list .list-label .title {
  font-size: 18px;
  font-weight: bold;
}

.box-list .list-label .more {
  padding-top: 4px;
  font-size: 12px;
  cursor: pointer;
}

.bbs-name {
  font-size: 14px;
  margin-bottom: 12px;
}

.bbs-post-title {
  font-size: 20px;
  border-bottom: 1px solid #dfdfdf;
  font-weight: bold;
  padding-bottom: 20px;
}

.bbs-post-content {
  min-height: 250px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dfdfdf;
}

.page-userClass {
  /* .box-cover */
}

.page-userClass .box-cover {
  position: relative;
  min-height: 250px;
  margin-bottom: 0px;
  background-color: white;
}

.page-userClass .box-cover .userInfo {
  position: absolute;
  left: 130px;
  bottom: 59px;
  font-size: 14px;
}

.page-userClass #btns-userClass {
  border-left: 1px solid #d2d2d2;
  background-color: white;
  margin-left: 150px;
}

.page-userClass #btns-userClass ul li {
  border-right: 1px solid #d2d2d2;
  float: left;
}

.page-userClass #btns-userClass ul li a {
  display: block;
  padding: 8px 40px;
}

.page-userClass #btns-userClass ul li a:hover {
  background-color: #dfe3ed;
}

.page-userClass #btns-userClass ul li.selected a {
  background-color: #e6e8ed;
}

.page-userClass #btns-userClass ul li::after {
  clear: both;
}

.block-sidebar {
  min-height: 130px;
  width: 23%;
  overflow: hidden;
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .block-sidebar {
    width: 240px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .block-sidebar {
    width: 240px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .block-sidebar {
    width: 240px;
  }
}

.block-user {
  min-height: 130px;
  overflow: hidden;
  background-color: white;
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .block-user {
    width: 240px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .block-user {
    width: 240px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .block-user {
    width: 240px;
  }
}

.block-user .user-relationship {
  font-size: 12px;
  width: 100%;
  margin-top: 9px;
  padding: 0px 20px;
}

.block-user .user-relationship a {
  width: 50%;
  text-align: center;
  display: block;
  color: #808080;
}

.block-user .user-relationship a:hover {
  color: black;
}

.block-user .user-relationship .btn-follower {
  border-right: 0px;
}

.block-user .user-relationship .btn-relationship {
  border: 1px solid #e6e6e6;
  padding: 10px;
  cursor: pointer;
}

.block-user .user-relationship .btn-relationship:hover {
  background-color: #e6e6e6;
}

.block-user .user-relationship .btn-relationship.btn-follower {
  border-right: 0px;
}

.block-user .label-user-lv {
  margin-bottom: 6px;
}

.block-user .btn-statistics {
  font-size: 12px;
}

.block-user .btn-statistics a {
  color: #989898;
}

.block-user .btn-statistics a:hover {
  color: #606060;
}

.block-user .bar-user-exp {
  height: 10px;
  background-color: #e6e6e6;
  width: 100%;
  margin-bottom: 6px;
  position: relative;
}

.block-user .bar-user-exp .bar-user-exp-current {
  width: 10%;
  height: 100%;
  background-color: #5275d0;
  float: left;
}

.block-user .bar-user-exp .bar-user-exp-remaining {
  width: 10%;
  height: 100%;
  float: left;
}

.block-user .label-remaining-exp {
  font-size: 12px;
}

.block-user .userBlock-top {
  position: relative;
}

.block-user .userBlock-top .userBorder {
  width: 100%;
  height: 121px;
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
}

.block-user .userBlock-top .editable {
  cursor: pointer;
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .block-user .userBlock-top.with-desc {
    height: 220px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .block-user .userBlock-top.with-desc {
    height: 220px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .block-user .userBlock-top.with-desc {
    height: 220px;
  }
}

@media only screen and (min-width: 1280px) {
  .block-user .userBlock-top.with-desc {
    height: 250px;
  }
}

.block-user .userBlock-bottom {
  width: 100%;
}

.block-user .userBlock-bottom .user-status {
  height: 93px;
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
}

.block-user .userBlock-bottom .user-status .user-apoint, .block-user .userBlock-bottom .user-status .user-lv {
  padding: 0px 10px;
  padding-top: 17px;
}

.block-user .userBlock-bottom .user-statistics-overview {
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.block-user .userBlock-bottom .user-statistics-overview ul {
  width: 100%;
}

.block-user .userBlock-bottom .user-statistics-overview ul li {
  float: left;
  width: 50%;
  border-top: 1px solid #dadddf;
}

.block-user .userBlock-bottom .user-statistics-overview ul li a {
  background-color: #f0f2f5;
  display: block;
  padding: 14px;
}

.block-user .userBlock-bottom .user-statistics-overview ul li a:hover {
  background-color: #e9e9e9;
}

.block-user .userBlock-bottom .user-statistics-overview ul li:first-child {
  border-right: 1px solid #dadddf;
}

.block-user .userBlock-bottom .user-statistics-overview ul li:after {
  clear: both;
  content: " ";
  display: block;
}

.block-user .userBlock-bottom .user-statistics-overview ul li .statistic-label {
  color: #666666;
  font-size: 14px;
  margin-bottom: 10px;
}

.block-user .userBlock-bottom .user-statistics-overview ul li .statistic-count {
  font-size: 18px;
}

.block-user .userBlock-bottom .user-statistics-overview ul li .linkbox-add {
  font-size: 12px;
  background-color: white;
  padding: 6px;
  margin-top: 8px;
  border: 1px solid #dbdbdb;
  color: #4f82b9;
  cursor: pointer;
}

.block-user .userBlock-bottom .user-statistics-overview ul li .linkbox-add .add-label {
  padding-top: 2px;
}

.block-user .userBlock-bottom .user-statistics-overview ul li .linkbox-add .add-icon {
  padding-top: 2px;
  font-size: 14px;
}

.block-user .userBlock-bottom .user-statistics-overview ul li .linkbox-add i {
  color: #959595;
}

.block-user .userBlock-bottom .user-statistics-overview ul li .linkbox-add:hover {
  background-color: #e9e9e9;
}

.block-user .userBlock-bottom .user-statistics-overview .btn-mycourses {
  display: block;
  padding: 18px;
  font-size: 17px;
  text-decoration: none;
}

.block-user .userBlock-bottom .user-statistics-overview .btn-mycourses:hover {
  text-decoration: none;
  background-color: #f7f7f7;
}

.userCover {
  position: relative;
  background-image: url("/_src/common/pageDefaultBackCover.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 130px;
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .userCover {
    height: 100px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .userCover {
    height: 100px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .userCover {
    height: 100px;
  }
}

#page-user-class {
  margin-top: 10px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 708px) {
  #page-user-class {
    margin-top: 0px;
  }
}

#page-user-class .user-page-title {
  font-size: 18px;
  margin-bottom: 16px;
}

#page-user-class #user-class-recommendation {
  float: left;
  min-height: 100px;
}

@media only screen and (max-width: 708px) {
  #page-user-class #user-class-recommendation {
    width: 98%;
    margin: 1% auto;
    float: inherit;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #page-user-class #user-class-recommendation {
    width: 64%;
    margin-left: 1%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #page-user-class #user-class-recommendation {
    width: 69%;
    margin-left: 1%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #page-user-class #user-class-recommendation {
    width: 74%;
    margin-left: 1%;
  }
}

@media only screen and (min-width: 1280px) {
  #page-user-class #user-class-recommendation {
    width: 74%;
    margin-left: 1%;
  }
}

#page-user-class #user-class-recommendation .recommendation-group {
  min-height: 150px;
  margin-bottom: 10px;
}

#page-user-class #user-class-recommendation .recommendation-group .recommendation-group-label {
  font-size: 15px;
  color: #353535;
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #page-user-class .userInfo {
    position: absolute;
    bottom: 25px;
    width: 100%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #page-user-class .userInfo {
    position: absolute;
    bottom: 25px;
    width: 100%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #page-user-class .userInfo {
    position: absolute;
    bottom: 25px;
    width: 100%;
  }
}

@media only screen and (min-width: 1280px) {
  #page-user-class .userInfo {
    position: absolute;
    bottom: 15px;
    width: 100%;
  }
}

#page-user-class .userInfo .userImage {
  width: 100%;
}

@media only screen and (max-width: 708px) {
  #page-user-class .userInfo .userImage {
    position: absolute;
    bottom: 120px;
  }
}

@media only screen and (max-width: 708px) {
  #page-user-class .userInfo .userImage.with-desc {
    position: absolute;
    bottom: 140px;
  }
}

#page-user-class .userInfo .userImage .userImage-wrapper {
  width: 95px;
  height: 95px;
  margin: 0 auto 0;
  display: block;
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #page-user-class .userInfo .userImage .userImage-wrapper {
    width: 75px;
    height: 75px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #page-user-class .userInfo .userImage .userImage-wrapper {
    width: 75px;
    height: 75px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #page-user-class .userInfo .userImage .userImage-wrapper {
    width: 75px;
    height: 75px;
  }
}

@media only screen and (min-width: 1280px) {
  #page-user-class .userInfo .userImage .userImage-wrapper {
    width: 95px;
    height: 95px;
  }
}

#page-user-class .userInfo .userImage .userImage-wrapper .userblock-profile-image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

#page-user-class .userInfo .userImage img {
  width: 95px;
  height: 95px;
  margin: 0 auto 0;
  display: block;
}

@media only screen and (max-width: 708px) {
  #page-user-class .userInfo .userImage img {
    width: 75px;
    height: 75px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #page-user-class .userInfo .userImage img {
    width: 75px;
    height: 75px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #page-user-class .userInfo .userImage img {
    width: 75px;
    height: 75px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #page-user-class .userInfo .userImage img {
    width: 75px;
    height: 75px;
  }
}

@media only screen and (min-width: 1280px) {
  #page-user-class .userInfo .userImage img {
    width: 95px;
  }
}

#page-user-class .userInfo .userName {
  text-align: center;
  width: 100%;
  margin-top: 7px;
  font-weight: 400;
}

@media only screen and (max-width: 708px) {
  #page-user-class .userInfo .userName {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #page-user-class .userInfo .userName {
    font-size: 14px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #page-user-class .userInfo .userName {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #page-user-class .userInfo .userName {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1280px) {
  #page-user-class .userInfo .userName {
    font-size: 20px;
  }
}

#page-user-class .userInfo .user-desc {
  text-align: center;
  font-size: 12px;
  margin-top: 4px;
  color: #474747;
}

@media only screen and (max-width: 708px) {
  #page-user-class .userInfo .user-relationship {
    width: 80%;
    margin: 8px auto 0;
  }
}

#page-user-class .userInfo .user-narrow-btns {
  border-top: 1px solid #e9e9e9;
  margin-top: 8px;
}

#page-user-class .userInfo .user-narrow-btns ul li {
  float: left;
  width: 25%;
}

#page-user-class .userInfo .user-narrow-btns ul li a {
  display: block;
  padding: 8px;
}

#page-user-class .userInfo .user-narrow-btns ul li a .statistic-label {
  text-align: center;
  font-size: 13px;
}

#page-user-class .userInfo .user-narrow-btns ul li a .statistic-count {
  font-size: 11px;
  text-align: center;
}

#page-user-class .userInfo .user-narrow-btns ul li a:hover {
  background-color: #f5f5f5;
}

#page-user-class #userClass-content {
  min-height: 130px;
  width: 73%;
  float: right;
}

@media only screen and (max-width: 708px) {
  #page-user-class #userClass-content {
    width: 100%;
    float: inherit;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #page-user-class #userClass-content {
    width: 450px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #page-user-class #userClass-content {
    width: 550px;
  }
}

#page-user-class #userClass-content .userClass-selectBar {
  text-align: center;
  margin: 0 auto 0;
  margin-bottom: 35px;
  width: 300px;
}

#page-user-class #userClass-content .userClass-selectBar a {
  display: block;
  color: #b3b3b3;
  padding: 10px 20px;
  font-size: 15px;
}

@media only screen and (max-width: 708px) {
  #page-user-class #userClass-content .userClass-selectBar a {
    font-size: 14px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #page-user-class #userClass-content .userClass-selectBar a {
    font-size: 15px;
  }
}

#page-user-class #userClass-content .userClass-selectBar a:hover {
  color: #5380be;
}

#page-user-class #userClass-content .userClass-selectBar a.selected {
  color: #5380be;
}

#page-user-class #userClass-content .userClass-selectBar a i {
  margin-right: 4px;
}

#page-user-class #userClass-content .userClass-selectBar .divider {
  border-right: 1px solid #ccc;
  height: 15px;
  margin-top: 14px;
}

#page-user-class #userClass-content .userClass-list-content .userClass-course-list .box-course-list .list-cover {
  display: block;
  float: left;
}

@media only screen and (max-width: 708px) {
  #page-user-class #userClass-content .userClass-list-content .userClass-course-list .box-course-list .list-cover {
    width: 48%;
    margin: 1%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #page-user-class #userClass-content .userClass-list-content .userClass-course-list .box-course-list .list-cover {
    width: 48%;
    margin: 1%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #page-user-class #userClass-content .userClass-list-content .userClass-course-list .box-course-list .list-cover {
    width: 31%;
    margin: 1%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #page-user-class #userClass-content .userClass-list-content .userClass-course-list .box-course-list .list-cover {
    width: 23%;
    margin: 1%;
  }
}

@media only screen and (min-width: 1280px) {
  #page-user-class #userClass-content .userClass-list-content .userClass-course-list .box-course-list .list-cover {
    width: 23%;
    margin: 1%;
  }
}

#page-user-class #userClass-content .userClass-list-content .userClass-course-list .box-course-list .list-cover .list-cover-image {
  position: relative;
  margin-bottom: 10px;
}

#page-user-class #userClass-content .userClass-list-content .userClass-course-list .box-course-list .list-cover .list-cover-image .list-cover-posts-count {
  position: absolute;
  height: 100%;
  width: 30%;
  background-color: black;
  color: white;
  text-align: center;
  right: 0;
  padding-top: 42px;
  opacity: 0.70;
  filter: alpha(opacity=70);
}

#page-user-class #userClass-content .userClass-list-content .userClass-course-list .box-course-list .list-cover .list-cover-image .list-cover-image-background {
  width: 100%;
  height: 126px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

#page-user-class #userClass-content .userClass-list-content .userClass-course-list .box-course-list .list-cover .list-info .list-title {
  height: 20px;
  overflow: hidden;
}

#page-user-class #userClass-content .box-class-search-input {
  width: 85%;
}

#page-user-class #userClass-content .box-class-search-input .input-class-search {
  border-top: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  box-shadow: none;
}

#page-user-class #userClass-content .box-user-list {
  float: left;
}

#page-user-class #userClass-content .box-user-list .list-cover {
  display: block;
  padding: 8px;
}

#page-user-class #userClass-content .box-user-list .list-cover .list-cover-image {
  margin-bottom: 8px;
}

#page-user-class #userClass-content .box-user-list .list-cover:hover {
  background-color: #eaeaea;
}

#page-user-class #userClass-content .box-user-list .list-cover .list-user-label {
  font-size: 13px;
}

#page-user-class #userClass-content .box-board-list .user-info {
  float: left;
}

@media only screen and (max-width: 708px) {
  #page-user-class #userClass-content .box-board-list .user-info {
    width: 10%;
    float: inherit;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #page-user-class #userClass-content .box-board-list .user-info {
    width: 15%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #page-user-class #userClass-content .box-board-list .user-info {
    width: 18%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #page-user-class #userClass-content .box-board-list .user-info {
    width: 10%;
  }
}

@media only screen and (min-width: 1280px) {
  #page-user-class #userClass-content .box-board-list .user-info {
    width: 7%;
  }
}

#page-user-class #userClass-content .box-board-list .board-info {
  float: left;
}

@media only screen and (max-width: 708px) {
  #page-user-class #userClass-content .box-board-list .board-info {
    width: 88%;
    float: inherit;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #page-user-class #userClass-content .box-board-list .board-info {
    width: 78%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #page-user-class #userClass-content .box-board-list .board-info {
    width: 65%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #page-user-class #userClass-content .box-board-list .board-info {
    width: 65%;
  }
}

@media only screen and (min-width: 1280px) {
  #page-user-class #userClass-content .box-board-list .board-info {
    width: 90%;
  }
}

#page-user-class #userClass-content .box-board-list .list-cover {
  display: block;
  padding: 10px;
  background-color: white;
  margin-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}

#page-user-class #userClass-content .box-board-list .list-cover:hover {
  background-color: white;
}

#page-user-class #userClass-content .box-board-list .img-circle {
  width: 50px;
  margin-right: 10px;
}

#page-user-class #userClass-content .box-board-list .board-content {
  font-size: 13px;
  padding: 8px;
  border: 1px solid #ddd;
  margin: 4px;
  overflow: hidden;
}

#page-user-class #userClass-content .box-board-list .board-date {
  font-size: 12px;
  color: #ddd;
}

#page-user-class #userClass-content .box-list .box-course-list .list-cover .list-info {
  width: 70%;
  text-align: justify;
}

#page-user-class #userClass-content .box-list .box-course-list .list-cover .list-info-2 {
  width: 10%;
}

#page-user-class #userClass-content .box-list .box-course-list .list-cover .list-info-2 .btn {
  width: 100%;
}

#page-user-class #userClass-content .box-list .box-course-list .list-cover .list-info-2 .btn a {
  color: white;
}

#page-user-class #userClass-content .box-list .box-course-list .list-cover:hover {
  background-color: white;
}

#page-user-class #userClass-timeline {
  min-height: 130px;
  width: 43%;
  float: left;
  margin: 0px 1%;
}

@media only screen and (max-width: 708px) {
  #page-user-class #userClass-timeline {
    width: 100%;
    float: inherit;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #page-user-class #userClass-timeline {
    width: 450px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #page-user-class #userClass-timeline {
    width: 550px;
  }
}

#page-user-class #userClass-sidebar {
  float: left;
  width: 30%;
  background-color: white;
  padding: 1%;
  border: 1px solid #e0e0e0;
}

@media only screen and (max-width: 708px) {
  #page-user-class #userClass-sidebar {
    display: none;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #page-user-class #userClass-sidebar {
    display: none;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #page-user-class #userClass-sidebar {
    display: none;
  }
}

#page-user-class #userClass-settings .form-setting {
  position: relative;
  margin-top: 15px;
}

#page-user-class #userClass-settings .form-setting .setting-label {
  float: left;
  width: 22%;
  padding-left: 8%;
}

#page-user-class #userClass-settings .form-setting .setting-body {
  float: left;
  width: 70%;
}

#page-user-class .sidebar-category .sidebar-category-list table {
  display: none;
  border-collapse: collapse;
  width: 100%;
}

#page-user-class .sidebar-category .sidebar-category-list table th, #page-user-class .sidebar-category .sidebar-category-list table td {
  width: 33.33%;
  border: 1px solid #e6e6e6;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

#page-user-class .sidebar-category .sidebar-category-list table td {
  background-color: white;
  width: 33.33%;
  cursor: pointer;
  height: 76.68px;
  font-size: 12px;
  padding: 14px;
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #page-user-class .sidebar-category .sidebar-category-list table td {
    height: 76.68px;
    font-size: 12px;
    padding: 14px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #page-user-class .sidebar-category .sidebar-category-list table td {
    height: 89.88px;
    font-size: 13px;
    padding: 15px;
  }
}

@media only screen and (min-width: 1280px) {
  #page-user-class .sidebar-category .sidebar-category-list table td {
    height: 99.89px;
    font-size: 14px;
    padding: 19px;
  }
}

#page-user-class .sidebar-category .sidebar-category-list table td:hover {
  background-color: #ebebeb;
}

#page-user-class .sidebar-category .sidebar-category-list table td.empty {
  background-color: #f4f4f4;
}

#page-user-class .sidebar-category .sidebar-category-list table.active {
  display: table;
}

/* End page-user-class */
.sidebar-block {
  margin-top: 20px;
}

.sidebar-block .sidebar-top-bar {
  margin-bottom: 18px;
  margin-bottom: 20px;
}

.sidebar-block .sidebar-top-bar .sidebar-label {
  font-size: 18px;
  font-weight: 400;
  float: left;
  color: black;
}

.sidebar-block .sidebar-top-bar .sidebar-more {
  float: right;
  font-size: 13px;
  padding-top: 5px;
}

.sidebar-block .sidebar-top-bar .sidebar-more a {
  color: #b5b5b5;
}

.sidebar-block .sidebar-top-bar .sidebar-more a:hover {
  color: #7d7d7d;
}

@media only screen and (max-width: 708px) {
  .sidebar-search #input-sidebar-search {
    width: 80%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .sidebar-search #input-sidebar-search {
    width: 70%;
    font-size: 11px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .sidebar-search #input-sidebar-search {
    width: 82%;
  }
}

@media only screen and (min-width: 1280px) {
  .sidebar-search #input-sidebar-search {
    width: 84%;
  }
}

.sidebar-search .sidebar-search-box {
  padding: 20px;
  background-color: #525559;
}

.sidebar-search .btn-action-search {
  padding: 8px 11px;
  color: white;
  background-color: #00a651;
  font-size: 17px;
}

.sidebar-banner a {
  display: block;
  width: 100%;
  height: 100%;
}

.sidebar-banner a img {
  width: 100%;
}

.sidebar-category .sidebar-top-bar {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 18px;
}

.sidebar-category .sidebar-categories {
  margin-bottom: 20px;
}

.sidebar-category .sidebar-category-master {
  margin-right: 18px;
  cursor: pointer;
  font-size: 14px;
  color: #cccccc;
}

@media only screen and (max-width: 708px) {
  .sidebar-category .sidebar-category-master {
    font-size: 10px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .sidebar-category .sidebar-category-master {
    font-size: 10px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .sidebar-category .sidebar-category-master {
    font-size: 12px;
    margin-right: 8px;
  }
}

.sidebar-category .sidebar-category-master span {
  display: inline-block;
  height: 15px;
  overflow: hidden;
  position: relative;
  width: 15px;
}

.sidebar-category .sidebar-category-master span i.fa-circle {
  font-size: 8px;
}

.sidebar-category .sidebar-category-master span i.fa-circle:before {
  left: 0;
  position: absolute;
  top: 3px;
}

.sidebar-category .sidebar-category-master.active, .sidebar-category .sidebar-category-master:hover {
  color: #4e6fac;
}

.sidebar-category .sidebar-category-list table {
  display: none;
  border-collapse: collapse;
  width: 100%;
}

.sidebar-category .sidebar-category-list table th, .sidebar-category .sidebar-category-list table td {
  width: 33.33%;
  border: 1px solid #e6e6e6;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.sidebar-category .sidebar-category-list table td {
  width: 33.33%;
  cursor: pointer;
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .sidebar-category .sidebar-category-list table td {
    height: 76.68px;
    font-size: 12px;
    padding: 14px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .sidebar-category .sidebar-category-list table td {
    height: 89.88px;
    font-size: 14px;
    padding: 19px;
  }
}

@media only screen and (min-width: 1280px) {
  .sidebar-category .sidebar-category-list table td {
    height: 99.89px;
    font-size: 14px;
    padding: 21px;
  }
}

.sidebar-category .sidebar-category-list table td:hover {
  background-color: #ebebeb;
}

.sidebar-category .sidebar-category-list table td.empty {
  background-color: #f4f4f4;
}

.sidebar-category .sidebar-category-list table.active {
  display: table;
}

.sidebar-bbs .sidebar-top-bar {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 18px;
}

.sidebar-bbs .sidebar-bbs-posts ul li a {
  display: block;
  padding: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}

.sidebar-bbs .sidebar-bbs-posts ul li a:hover {
  background-color: #f4f4f4;
}

#t-line-recommends {
  position: relative;
}

#t-line-recommends .t-recommend-content {
  float: left;
  height: 150px;
  width: 29%;
  margin: 2%;
  border: 1px solid #e0e0e0;
}

.t-line {
  background-color: white;
  width: 100%;
  margin-bottom: 20px;
  padding: 14px;
  border: 1px solid #e0e0e0;
}

.t-line .t-line-header .t-line-userimage {
  width: 35px;
}

.t-line .t-line-header .t-line-info-box {
  font-size: 14px;
  margin-left: 10px;
}

.t-line .t-line-header .t-line-info-box .t-line-info {
  font-size: 10px;
  margin-top: 2px;
  color: #a0a0a0;
}

.t-line .t-line-body {
  padding: 8px;
  font-size: 14px;
}

.t-line .t-line-statistics {
  font-size: 12px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 8px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.t-line .t-line-actions li {
  float: left;
  margin-right: 10px;
  font-size: 12px;
}

#block-manager .tabs {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}

#block-manager .manager-label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

#block-manager .manager-sub-label {
  font-size: 14px;
  color: #666666;
}

#block-manager .manager-block {
  margin-top: 20px;
  padding-bottom: 20px;
}

#block-manager .manager-block.no-line {
  border-bottom: 0px;
}

#block-manager .box-primary-image {
  position: relative;
  max-width: 320px;
  border: 1px solid #dfdfdf;
  min-height: 171px;
}

#block-manager .box-primary-image .uiblock-primary-image {
  width: 100%;
  background-color: black;
  height: 100%;
  display: block;
  position: absolute;
  display: none;
}

#block-manager .box-primary-image #preview-post-type {
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 8px;
  background-color: #6e8eba;
  color: white;
}

#block-manager .btn-zone {
  position: relative;
  border-top: 2px solid #d6d6d6;
  padding-top: 20px;
  margin-top: 10px;
}

#block-manager .btn-zone .btn {
  padding: 12px 37px;
}

#block-manager .btn-zone #btn-del-post {
  position: absolute;
  background-color: #838391;
  color: white;
  right: 0px;
  top: 20px;
}

#block-manager .btn-zone #btn-del-post:hover {
  background-color: #e24b4b;
}

#block-manager .btn-zone #btn-cancel:hover {
  background-color: #dddddd;
}

#block-manager .btn-zone .btn-zone-center {
  width: 220px;
  margin: 0 auto 0;
}

#block-manager .radiobox-custom {
  cursor: pointer;
}

#block-manager .radiobox-custom .radio_or_checkbox {
  width: 20px;
  height: 20px;
}

#block-manager .radiobox-custom .checkator_holder .checkator_element {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

#block-manager .radiobox-custom .checkator_holder .checkator_element:after, #block-manager .radiobox-custom .checkator_holder .checkator_element:focus {
  background-color: #0d84ea;
}

#block-manager #btn-selectContentType {
  padding: 20px;
  text-align: center;
  margin-top: 10px;
  color: #a1abe0;
  border: 1px #a1abe0 dashed;
  cursor: pointer;
}

#block-manager #btn-selectContentType:hover {
  color: #6877c5;
  border: 1px #6877c5 dashed;
}

#block-manager .attached-files li li, #block-manager .owners li, #block-manager .tags li {
  float: left;
  display: block;
  background-color: white;
  margin: 5px;
  border: 1px solid #dfdfdf;
}

#block-manager .attached-files li .boxLabel, #block-manager .owners .boxLabel, #block-manager .tags .boxLabel {
  padding: 6px;
}

#block-manager .attached-files li .tagLabel, #block-manager .owners .tagLabel, #block-manager .tags .tagLabel {
  padding: 4px 10px;
  font-size: 13px;
  text-align: center;
}

#block-manager .attached-files li .remove, #block-manager .owners .remove, #block-manager .tags .remove {
  padding: 5px;
  font-size: 12px;
  background-color: #d0d0ff;
  color: #919191;
  cursor: pointer;
}

#block-manager .attached-files li .remove:hover, #block-manager .owners .remove:hover, #block-manager .tags .remove:hover {
  background-color: #6a6ccb;
  color: white;
}

#block-manager .attached-files li .leftBox, #block-manager .owners .leftBox, #block-manager .tags .leftBox {
  float: left;
}

#block-manager #publish-state-set-2 {
  border-top: 1px solid #ccc;
  margin-top: 8px;
  padding-top: 8px;
}

#block-manager #manager-category .category-block {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #e6e6e6;
}

#block-manager #manager-category .category-block.block-first {
  padding-top: 0px;
  margin-top: 0px;
  border-top: 0px;
}

#selectContentType-modal .btn-openContentModal {
  height: 150px;
  padding: 10px;
  padding-top: 30px;
  text-align: center;
}

#selectContentType-modal .btn-openContentModal .provider-log {
  width: 180px;
  padding: 20px;
}

#selectContentType-modal .btn-openContentModal:hover {
  border: 2px solid #646b81;
}

#selectContentType-modal .btn-openContentModal:hover .provider-log {
  padding: 10px;
}

#content-preview-modal .preview-type {
  font-size: 16px;
  margin-bottom: 14px;
}

#content-preview-modal .preview-name {
  font-size: 20px;
  margin-bottom: 14px;
}

#content-preview-modal .preview-image {
  text-align: center;
}

#content-preview-modal .preview-image img {
  width: 100%;
}

.content-modal .btns {
  padding: 8px;
  color: #8a8a8a;
  cursor: pointer;
}

.content-modal .btns:hover {
  color: #4c4c4c;
}

.list-box-contents {
  margin-top: 20px;
}

.list-box-contents .box-content {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: white;
}

.list-box-contents .list-handle {
  font-size: 20px;
  padding: 10px 0px;
  cursor: pointer;
  color: #787878;
}

.list-box-contents .ui-sortable-helper .list-handle {
  color: #232323;
}

.list-box-contents .list-image {
  width: 10%;
  position: relative;
  margin-left: 10px;
}

.list-box-contents .list-image img {
  width: 100%;
}

.list-box-contents .list-title {
  width: 50%;
  margin-left: 10px;
}

#uploadZone {
  min-height: 200px;
  min-width: 320px;
  border: 2px dashed #0087F7;
  text-align: center;
  margin-top: 25px;
}

#uploadZone .dz-message {
  color: #5f5f5f;
}

#uploadZone .dz-message .msg-1 {
  font-size: 18px;
  margin-bottom: 20px;
}

#uploadZone .dz-message .msg-2 {
  font-size: 25px;
  color: #868686;
}

#uploadZone .dz-message .msg-3 {
  font-size: 15px;
}

#file-manager-modal #btn-tabs {
  border-bottom: 1px solid #ccc;
}

#file-manager-modal .tab-panel {
  display: none;
}

#file-manager-modal .tab-panel.selected {
  display: block;
}

#file-manager-modal #panel-file-upload {
  margin-top: 25px;
}

#file-manager-modal #panel-file-upload .dropzone {
  border: 2px dashed #0087F7;
  border-radius: 5px;
  background: white;
}

#file-manager-modal #panel-file-upload .dropzone .dz-message {
  color: #5f5f5f;
}

#file-manager-modal #panel-file-upload .dropzone .dz-message .msg-1 {
  font-size: 18px;
  margin-bottom: 20px;
}

#file-manager-modal #panel-file-upload .dropzone .dz-message .msg-2 {
  font-size: 25px;
  color: #868686;
}

#file-manager-modal #panel-file-upload .dropzone .dz-message .msg-3 {
  font-size: 15px;
}

#file-manager-modal .panels {
  margin-top: 15px;
}

#file-manager-modal .panels .box-file-list {
  margin-bottom: 15px;
  margin-top: 15px;
  cursor: pointer;
}

#file-manager-modal .panels .box-file-list:hover .list-cover-image {
  background-repeat: no-repeat;
  background-size: auto 120%;
  -webkit-animation: fadeInFromNone 0.5s ease-out;
  -moz-animation: fadeInFromNone 0.5s ease-out;
  -o-animation: fadeInFromNone 0.5s ease-out;
  animation: fadeInFromNone 0.5s ease-out;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

#file-manager-modal .panels .box-file-list:hover .list-cover-file {
  font-size: 80px;
  -webkit-animation: fadeInFromNone 0.5s ease-out;
  -moz-animation: fadeInFromNone 0.5s ease-out;
  -o-animation: fadeInFromNone 0.5s ease-out;
  animation: fadeInFromNone 0.5s ease-out;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}

#file-manager-modal .panels .box-file-list .list-cover-image {
  background-repeat: no-repeat;
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center center;
  border: 1px solid #dddddd;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  -moz-opacity: 0.70;
  -khtml-opacity: 0.70;
  opacity: 0.70;
}

#file-manager-modal .panels .box-file-list .list-cover-file {
  width: 100%;
  height: 110px;
  text-align: center;
  font-size: 75px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  -moz-opacity: 0.70;
  -khtml-opacity: 0.70;
  opacity: 0.70;
}

#file-manager-modal .panels .box-file-list .list-cover-file.pdf {
  color: #c83546;
}

#file-manager-modal .panels .box-file-list .list-cover-file.doc {
  color: #112fc5;
}

#file-manager-modal .panels .box-file-list .list-cover-text {
  white-space: nowrap;
  overflow: hidden;
  margin-top: 10px;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
}

#file-manager-detail-modal .list-cover-file {
  width: 100%;
  height: 110px;
  text-align: center;
  font-size: 75px;
}

#file-manager-detail-modal .list-cover-file.pdf {
  color: #c83546;
}

#file-manager-detail-modal .list-cover-file.doc {
  color: #112fc5;
}

#file-manager-detail-modal #file-preview img {
  max-width: 100%;
}

@-webkit-keyframes fadeInFromNone {
  0% {
    display: block;
    opacity: 0.75;
  }
  1% {
    display: block;
    opacity: 0.75;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@-moz-keyframes fadeInFromNone {
  0% {
    display: block;
    opacity: 0.75;
  }
  1% {
    display: block;
    opacity: 0.75;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@-o-keyframes fadeInFromNone {
  0% {
    display: block;
    opacity: 0.75;
  }
  1% {
    display: block;
    opacity: 0.75;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeInFromNone {
  0% {
    display: block;
    opacity: 0.75;
  }
  1% {
    display: block;
    opacity: 0.75;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

.block-tooltip {
  color: #b6bbc2;
  cursor: pointer;
}

.block-tooltip:hover, .block-tooltip .active {
  color: #3dacdb;
}

.tipso_style {
  border-bottom: 0px;
}

.tipso_bubble {
  border: 1px solid #d2d2d4;
  text-align: left;
  padding: 10px;
  -webkit-box-shadow: 5px 5px 14px 0px #ebebeb;
  -moz-box-shadow: 5px 5px 14px 0px #ebebeb;
  box-shadow: 5px 5px 14px 0px #ebebeb;
}

.tipso_bubble.default {
  font-size: 12px;
}

#container {
  background-color: #f4f4f4;
  /* END centerContainer */
  /* ERROR  */
  /* ADs */
}

#container .btn_social i {
  font-size: 30px;
}

#container .left-box {
  float: left;
}

@media only screen and (max-width: 708px) {
  #container .left-box {
    width: 100%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #container .left-box {
    width: 100%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #container .left-box {
    width: 800px;
  }
}

#container .right-box {
  float: left;
}

@media only screen and (max-width: 708px) {
  #container .right-box {
    width: 100%;
    height: 140px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #container .right-box {
    width: 100%;
    height: 140px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #container .right-box {
    width: 400px;
  }
}

#container .box {
  background-color: white;
  -moz-box-sizing: border-box;
}

@media only screen and (max-width: 708px) {
  #container .box {
    margin: 0px;
  }
}

#container .box .box-video {
  width: 100%;
}

@media only screen and (max-width: 708px) {
  #container .box .box-video {
    height: 250px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #container .box .box-video {
    height: 360px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #container .box .box-video {
    height: 450px;
  }
}

#container .box .box-label {
  font-family: Roboto, arial, sans-serif;
  font-size: 17px;
  padding-top: 15px;
  padding-left: 15px;
  font-weight: bold;
}

@media only screen and (max-width: 708px) {
  #container .box .box-label {
    margin-bottom: 10px;
  }
}

#container .box .box-title {
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

#container .box .box-content {
  padding: 4px;
}

#container .box .box-content ul {
  margin-bottom: 15px;
}

#container .box .box-content ul li a {
  font-size: 12px;
}

#container .box .infolist a {
  font-size: 12px;
}

#container .box .sideContent ul li {
  height: 150px;
  margin-top: 10px;
  padding: 0px;
  background-position: 50% 50%;
  /* background-size: cover; */
  background-repeat: no-repeat;
  position: relative;
}

@media only screen and (max-width: 708px) {
  #container .box .sideContent ul li {
    max-width: 300px;
    margin: 0 auto 0;
  }
}

#container .box .sideContent ul li a {
  transition: all 0.5s ease;
  display: block;
  width: 100%;
  height: 100%;
}

#container .box .sideContent ul li a .postTitle {
  padding: 10px;
  color: #2d2d2d;
  position: absolute;
  width: 100%;
  bottom: 0px;
  font-weight: bold;
}

#container .box .sideContent ul li a:hover {
  display: block;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.4;
}

#container .box .sideContent ul li a:hover .postTitle {
  color: white;
}

#container .box .sideContent ul li .bg {
  display: block;
  height: 41px;
  width: 100%;
  background-color: white;
  opacity: 0.4;
  filter: alpha(opacity=14);
  position: absolute;
  bottom: 0px;
}

#container .box#profileBox {
  padding: 0px;
  padding-bottom: 10px;
}

#container .box#profileBox #profileCover {
  background-size: cover;
  height: 75px;
  position: relative;
}

#container .box#profileBox #profileImg {
  position: relative;
  margin: 0 auto 0;
  width: 45px;
  bottom: -50px;
  border: 1px solid #ccc;
}

#container .box#profileBox #profileImg img {
  width: 100%;
}

#container .box#profileBox #profileName {
  text-align: center;
  font-size: 13px;
  margin-top: 30px;
}

#container .box#profileBox #profileState ul {
  margin-top: 10px;
}

#container .box#profileBox #profileState ul li {
  width: 33%;
  text-align: center;
  margin: 0px;
}

#container .box#profileBox #profileState ul li .scoreTitle {
  font-size: 11px;
  font-weight: bold;
}

#container .box#profileBox #profileState ul li .scoreNum {
  font-size: 15px;
  line-height: 33px;
}

#container .adBox.box {
  padding: 4px;
  min-height: 90px;
}

#container #leftContainer {
  width: 230px;
}

@media only screen and (max-width: 708px) {
  #container #leftContainer {
    display: none;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #container #leftContainer {
    width: 210px;
  }
}

@media only screen and (max-width: 708px) {
  #container #centerContainer.fl {
    float: none;
  }
}

#container #centerContainer {
  width: 583px;
  margin: 0px 8px;
  /* END ShareBox */
  /* PostLst Part */
}

@media only screen and (max-width: 708px) {
  #container #centerContainer {
    width: inherit;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #container #centerContainer {
    width: 520px;
    margin: 0px 5px;
  }
}

#container #centerContainer #shareBox .paragraph:first-child {
  margin-top: 0px;
}

#container #centerContainer #shareBox .paragraph {
  width: 100%;
  margin-top: 8px;
}

#container #centerContainer #shareBox .paragraph .input-group-addon {
  padding: 0px 8px;
  height: inherit;
  background-color: #f5f5f5;
  border: 0px;
}

#container #centerContainer #shareBox .paragraph .input-group-addon i {
  color: #747474;
}

#container #centerContainer #shareBox .paragraph .removeParagraph, #container #centerContainer #shareBox .paragraph .moveParagraph {
  cursor: pointer;
}

#container #centerContainer #shareBox .paragraph .textParagraph {
  transition: all 0.5s ease;
  min-height: 25px;
  padding: 0px 8px;
}

#container #centerContainer #shareBox .paragraph .textParagraph:focus {
  padding: 8px;
}

#container #centerContainer #shareBox #addContent {
  margin: 10px 0px;
  border: 1px solid #e2e2e2;
}

#container #centerContainer #shareBox #addContent a {
  transition: all 0.5s ease;
  display: block;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding: 5px;
  color: #ccc;
}

#container #centerContainer #shareBox #addContent a:hover {
  color: #747474;
}

#container #centerContainer #shareBox #addContent #submitTale {
  border: 0px;
}

#container #centerContainer .postLst ul li {
  /*
					margin-top:8px;
					box-sizing: border-box;
			    border-radius: 3px;
			    background-color:white;
			    border: 1px solid #e3e3e3;
			    */
}

#container #centerContainer .postLst .boxImg {
  overflow: hidden;
  max-height: 300px;
  text-align: center;
  display: block;
}

#container #centerContainer .postLst .boxImg img {
  max-width: 100%;
}

#container #centerContainer .postLst .lst_title {
  font-weight: bold;
  padding: 4px;
  font-size: 15px;
}

#container #centerContainer .postLst .lst_content {
  padding: 8px 8px 4px 8px;
  color: #373737;
  font-size: 12px;
}

#container #centerContainer .postLst .lst_tags {
  color: #c8c8c8;
  padding: 4px 8px 8px 8px;
  font-size: 10px;
}

#container #rightContainer {
  width: 310px;
}

@media only screen and (max-width: 708px) {
  #container #rightContainer {
    display: none;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #container #rightContainer {
    display: none;
  }
}

#container .errorBox {
  text-align: center;
  margin: 10% 0;
  font-weight: bold;
}

#container .errorMsg {
  font-weight: bold;
  font-size: 12px;
  margin: 15px;
  text-align: left;
  color: #c42332;
}

#container .sideAdBox {
  padding: 4px !important;
  margin: 0 auto 0;
}

#container #post-info-left {
  float: left;
  width: 69%;
}

@media only screen and (max-width: 708px) {
  #container #post-info-left {
    width: 100%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #container #post-info-left {
    width: 100%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #container #post-info-left {
    float: left;
    width: 69%;
  }
}

#container #post-info-right {
  float: right;
  width: 30%;
  min-height: 650px;
}

@media only screen and (max-width: 708px) {
  #container #post-info-right {
    display: none;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #container #post-info-right {
    display: none;
  }
}

#container #btn-open-desc {
  text-align: center;
  padding: 10px;
  cursor: pointer;
  margin-top: -10px;
  color: #717171;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ededed+0,e0e0e0+100&0+0,0.9+100 */
  background: -moz-linear-gradient(top, rgba(237, 237, 237, 0) 0%, rgba(224, 224, 224, 0.9) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(237, 237, 237, 0) 0%, rgba(224, 224, 224, 0.9) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(237, 237, 237, 0) 0%, rgba(224, 224, 224, 0.9) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ededed', endColorstr='#e6e0e0e0',GradientType=0 );
  /* IE6-9 */
}

#container #btn-open-desc:hover {
  color: black;
}

#container #post-desc-more {
  display: none;
}

#post-article .post-header {
  text-align: center;
  height: 300px;
  position: relative;
}

#post-article .post-header .post-header-background {
  padding: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#post-article .post-header .post-header-cover {
  height: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.45;
  filter: alpha(opacity=45);
}

#post-article .post-header .post-title-box {
  width: 100%;
  height: 100%;
  position: absolute;
  color: white;
  padding: 20px;
}

#post-article .post-header .post-title-box .post-title {
  font-size: 30px;
  margin-top: 10%;
}

#post-article .post-header .post-title-box .post-desc {
  margin-top: 10px;
  font-size: 15px;
}

#post-article .post-article {
  padding: 20px;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 10px;
}

#post-audio .post-header {
  text-align: center;
  height: 300px;
  position: relative;
}

#post-audio .post-header .post-header-background {
  padding: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#post-audio .post-header .post-header-cover {
  height: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  opacity: 0.45;
  filter: alpha(opacity=45);
}

#post-audio .post-header .post-title-box {
  width: 100%;
  height: 100%;
  position: absolute;
  color: white;
  padding: 20px;
}

#post-audio .post-header .post-title-box .post-title {
  font-size: 30px;
  margin-top: 10%;
}

#post-audio .post-header .post-title-box .post-desc {
  margin-top: 10px;
  font-size: 15px;
}

#post-audio .post-article {
  padding: 20px;
  border-bottom: 1px solid #dfdfdf;
  margin-bottom: 10px;
}

/*
#main-top{
	padding:25px 0px;
	
	#top-vod{
		position:relative;
		
		@include respond-to(res_lv1){
			
		}
		@include respond-to(res_lv2){
			
		}
		@include respond-to(res_lv3){
			float:left;
			width:65%;
		}
		@include respond-to(res_lv4){
			float:left;
			width:65%;
		}
		@include respond-to(res_lv5){
			float:left;
			width:65%;
		}
		
		.main-top-label{
			padding-bottom:24px;
			font-size:20px;
			font-weight:bold;
		}
		#slide-control-bar{
			width:150px;
			margin:20px auto 0;
			.btn-divider{
				color:#d6d6d6;
			}
			.btn-choose{
				
			}
			.btn-choose.selected{
	
			}			
		}
	}
	#vod-more{
	
		.btn-zone{
			padding-bottom:25px;
			font-size:19px;
			.btn-divider{
				font-size:13px;
				color:#dbdbdb;
				padding-top:5px;
				
			}			
		}
		@include respond-to(res_lv1){
			margin-top:15px;
		}
		@include respond-to(res_lv2){
			margin-top:15px;
		}
		@include respond-to(res_lv3){
			float:right;
			width:34%;
		}
		@include respond-to(res_lv4){
			float:right;
			width:34%;
		}
		@include respond-to(res_lv5){
			float:right;
			width:34%;
		}

		#slide-posts{
			overflow: hidden;
			@include respond-to(res_lv1){
				height:$slideHeight_lv1 - $btnChooseHeight;
			}
			@include respond-to(res_lv2){
				height:$slideHeight_lv2 - $btnChooseHeight;
			}
			@include respond-to(res_lv3){
				height:$slideHeight_lv3 - $btnChooseHeight;
			}
			@include respond-to(res_lv4){
				height:$slideHeight_lv4 - $btnChooseHeight;
			}
			@include respond-to(res_lv5){
				height:$slideHeight_lv5 - $btnChooseHeight;
			}
			ul{
				li{
					margin-bottom: 15px;
					a{
						display:block;
						position:relative;
						.slide-title{
							margin-top:20px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							-o-text-overflow: ellipsis;
							-ms-text-overflow: ellipsis;							
						}
						.bottomItem{
							position:absolute;
							padding:10px;
						    bottom: 0px;
						    .box-divider{
								color: #a9a9a9;
							    padding: 0px 10px;
							    font-size: 12px;							    
						    }
							.user-profile-image{
								float:left;
							}
							.user-profile-name{
								margin-top:5px;
								white-space: nowrap;
							    text-overflow: ellipsis;
							    -o-text-overflow: ellipsis;
							    -ms-text-overflow: ellipsis;
							    overflow: hidden;
							    float: right;
							    width: 80%;
							}
							.box-created{
								color: #a9a9a9;
								font-weight: 100;
								font-size: 12px;								
							}	    	
						}						
						
					}
				}
			}	
			.scrollbar-inner{
			@include respond-to(res_lv1){
				height:$slideHeight_lv1 - $btnChooseHeight;
			}
			@include respond-to(res_lv2){
				height:$slideHeight_lv2 - $btnChooseHeight;
			}
			@include respond-to(res_lv3){
				height:$slideHeight_lv3 - $btnChooseHeight;
			}
			@include respond-to(res_lv4){
				height:$slideHeight_lv4 - $btnChooseHeight;
			}
			@include respond-to(res_lv5){
				height:$slideHeight_lv5 - $btnChooseHeight;
			}
					ul{
						height:($slidePostsHeight+15)*5;	
					}

			}
			.slide-post{
				@include respond-to(res_lv1){
					float:left;
				}
				@include respond-to(res_lv2){
					float:left;
				}
				@include respond-to(res_lv3){
				}
				@include respond-to(res_lv4){
				}
				@include respond-to(res_lv5){
				}
			}		
	
			.slide-post-cover{
				background-size: cover;
				height: $slidePostsHeight;
				background-position: 50%;							
				float:left;
				width:39%;				
				@include respond-to(res_lv1){
					
				}
				@include respond-to(res_lv2){
		
				}
				@include respond-to(res_lv3){
				}
				@include respond-to(res_lv4){
				}
				@include respond-to(res_lv5){
					height: $slidePostsHeight;
				}		
			}
		}
	}
	.slide{
		.post-list-cover-image{
			@include respond-to(res_lv1){
				height:$slideHeight_lv1;
			}
			@include respond-to(res_lv2){
				height:$slideHeight_lv2;
			}
			@include respond-to(res_lv3){
				height:$slideHeight_lv3;
			}
			@include respond-to(res_lv4){
				height:$slideHeight_lv4;
			}
			@include respond-to(res_lv5){
				height:$slideHeight_lv5;
			}
		}
	}
	
}
*/
#univ-page-navi {
  margin: 20px auto 0;
}

#univ-page-navi .navi a {
  text-align: center;
  font-size: 18px;
  padding-bottom: 2px;
  /*
			@include respond-to(res_lv1){
				font-size:15px;
			}
			@include respond-to(res_lv2){
				font-size:15px;
			}
			@include respond-to(res_lv3){		
				font-size:16px;
			}
			@include respond-to(res_lv4){
				font-size:18px;
			}
			@include respond-to(res_lv5){
				font-size:18px;	 		
			}			
			*/
}

#univ-page-navi .navi a.selected {
  color: #0aa958;
  border-bottom: 2px solid #0aa958;
}

#page-copyright-guide .license-guide-container {
  margin-top: 20px;
}

#page-copyright-guide .license-guide-title {
  font-size: 18px;
  cursor: pointer;
  padding: 10px;
}

#page-copyright-guide .license-guide-box {
  background-color: #f2f4f7;
  float: left;
  margin: 1%;
  width: 23%;
  height: 200px;
  padding: 20px;
  cursor: pointer;
}

#page-copyright-guide .license-guide-box:hover, #page-copyright-guide .license-guide-box.selected {
  background-color: #d1d7e1;
}

@media only screen and (max-width: 708px) {
  #page-copyright-guide .license-guide-box {
    margin: 2%;
    width: 46%;
    height: 180px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #page-copyright-guide .license-guide-box {
    margin: 2%;
    width: 46%;
    height: 180px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #page-copyright-guide .license-guide-box {
    margin: 1%;
    width: 31%;
    height: 180px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #page-copyright-guide .license-guide-box {
    margin: 1%;
    width: 23%;
    height: 200px;
  }
}

@media only screen and (min-width: 1280px) {
  #page-copyright-guide .license-guide-box {
    margin: 1%;
    width: 23%;
    height: 200px;
  }
}

#page-copyright-guide .license-guide-content {
  display: none;
  padding: 20px;
  border: 1px solid #dfdfdf;
}

.copyrightGuide-detail-modal .guide-modal-header {
  margin: 40px;
  margin-bottom: 0px;
  border-bottom: 2px solid black;
  font-size: 25px;
  font-weight: bold;
  padding-bottom: 20px;
}

.copyrightGuide-detail-modal .guide-modal-header .btn-guide-detail-modal-close {
  font-size: 30px;
  position: absolute;
  right: 35px;
  top: 30px;
  cursor: pointer;
}

.copyrightGuide-detail-modal .guide-modal-header .guide-title {
  width: 70%;
}

.copyrightGuide-detail-modal .guide-modal-content {
  padding: 40px;
}

.copyrightGuide-detail-modal .guide-modal-content .guide-icon {
  width: 10%;
  color: white;
}

@media only screen and (max-width: 708px) {
  .copyrightGuide-detail-modal .guide-modal-content .guide-icon {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .copyrightGuide-detail-modal .guide-modal-content .guide-icon {
    width: 100%;
    margin-bottom: 15px;
  }
}

.copyrightGuide-detail-modal .guide-modal-content .icon-question {
  background-color: #005baa;
}

.copyrightGuide-detail-modal .guide-modal-content .icon-answer {
  background-color: #0aa958;
}

.copyrightGuide-detail-modal .guide-modal-content .icon-question, .copyrightGuide-detail-modal .guide-modal-content .icon-answer {
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 2px;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  border-radius: 90px;
}

.copyrightGuide-detail-modal .guide-modal-content .guide-question {
  margin-bottom: 20px;
}

.copyrightGuide-detail-modal .guide-modal-content .guide-question, .copyrightGuide-detail-modal .guide-modal-content .guide-answer {
  width: 80%;
  line-height: 1.7em;
  text-align: justify;
}

@media only screen and (max-width: 708px) {
  .copyrightGuide-detail-modal .guide-modal-content .guide-question, .copyrightGuide-detail-modal .guide-modal-content .guide-answer {
    width: 100%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .copyrightGuide-detail-modal .guide-modal-content .guide-question, .copyrightGuide-detail-modal .guide-modal-content .guide-answer {
    width: 100%;
  }
}

.copyrightGuide-detail-modal .guide-modal-footer {
  border-top: #d9d9d9;
  background-color: #f2f2f2;
  border-top: 1px solid #d9d9d9;
  width: 100%;
}

.copyrightGuide-detail-modal .guide-modal-footer .btn-prev {
  border-right: 1px solid #d9d9d9;
}

.copyrightGuide-detail-modal .guide-modal-footer .btn-prev, .copyrightGuide-detail-modal .guide-modal-footer .btn-next {
  padding: 25px 20px;
  font-size: 18px;
  position: relative;
  height: 94px;
  cursor: pointer;
}

@media only screen and (max-width: 708px) {
  .copyrightGuide-detail-modal .guide-modal-footer .btn-prev, .copyrightGuide-detail-modal .guide-modal-footer .btn-next {
    font-size: 24px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .copyrightGuide-detail-modal .guide-modal-footer .btn-prev, .copyrightGuide-detail-modal .guide-modal-footer .btn-next {
    font-size: 24px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .copyrightGuide-detail-modal .guide-modal-footer .btn-prev, .copyrightGuide-detail-modal .guide-modal-footer .btn-next {
    font-size: 19px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .copyrightGuide-detail-modal .guide-modal-footer .btn-prev, .copyrightGuide-detail-modal .guide-modal-footer .btn-next {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1280px) {
  .copyrightGuide-detail-modal .guide-modal-footer .btn-prev, .copyrightGuide-detail-modal .guide-modal-footer .btn-next {
    font-size: 23px;
  }
}

.copyrightGuide-detail-modal .guide-modal-footer .btn-arrow-navi {
  font-size: 35px;
  position: absolute;
  padding: 0px 10px;
}

.copyrightGuide-detail-modal .guide-modal-footer .btn-arrow-navi-left {
  left: 0px;
}

.copyrightGuide-detail-modal .guide-modal-footer .btn-arrow-navi-right {
  right: 0px;
}

.copyrightGuide-detail-modal .guide-modal-footer .next-num, .copyrightGuide-detail-modal .guide-modal-footer .prev-num {
  width: 10%;
}

.copyrightGuide-detail-modal .guide-modal-footer .next-title, .copyrightGuide-detail-modal .guide-modal-footer .prev-title {
  width: 80%;
}

@media only screen and (max-width: 708px) {
  .copyrightGuide-detail-modal .guide-modal-footer .next-title, .copyrightGuide-detail-modal .guide-modal-footer .prev-title {
    display: none;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .copyrightGuide-detail-modal .guide-modal-footer .next-title, .copyrightGuide-detail-modal .guide-modal-footer .prev-title {
    display: none;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .copyrightGuide-detail-modal .guide-modal-footer .next-title, .copyrightGuide-detail-modal .guide-modal-footer .prev-title {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .copyrightGuide-detail-modal .guide-modal-footer .next-title, .copyrightGuide-detail-modal .guide-modal-footer .prev-title {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1280px) {
  .copyrightGuide-detail-modal .guide-modal-footer .next-title, .copyrightGuide-detail-modal .guide-modal-footer .prev-title {
    font-size: 18px;
  }
}

.copyrightGuide-detail-modal .guide-modal-footer .btn-navi-content {
  width: 90%;
}

#tpl_a #header {
  height: 180px;
}

#tpl_a #header .wide #btn-master-category {
  float: none;
}

#tpl_b #header .wide {
  height: 117px;
}

#tpl_b #header .wide .utils .btn-show-login {
  margin-top: 18px;
}

#tpl_b #header .wide .utils .btn-show-login > a {
  margin-top: 4px;
  font-size: 12px;
  padding: 8px 18px;
  border: 1px solid #a7a7a7;
}

#tpl_b #header .wide .utils .btn-show-login .btn-auth-login {
  padding: 7px 13px;
  border: 1px solid #669dcc;
  font-size: 13px;
  cursor: pointer;
  margin-top: -3px;
}

#tpl_b #header .wide #btn-master-category {
  float: none;
  margin: 0px;
  background-color: white;
  height: 52px;
}

#tpl_b #header .wide #btn-master-category .btn-navi {
  padding: 15px 20px;
  color: black;
  font-size: 16px;
}

#tpl_b #header .wide #btn-master-category .btn-navi:hover {
  background-color: #e3e3e3;
  color: #378cd5;
}

#tpl_b #header .wide #btn-master-category #btn-zone-header ul {
  padding: 16px;
}

#tpl_b #header .wide #btn-master-category #btn-zone-header ul li {
  float: left;
  font-size: 14px;
  color: black;
  padding: 2px 10px;
  border-right: 1px solid black;
}

#tpl_b #header .wide #btn-master-category #btn-zone-header ul li:last-child {
  border-right: 0px;
}

#tpl_b #header .wide #wide-header-logo {
  width: 161px;
  height: 53px;
  margin-top: 11px;
}

#tpl_b #header .wide .header-left {
  float: left;
}

#tpl_b #header .wide #header-search-wrapper {
  padding-top: 12px;
}

#tpl_b #header .wide #header-search-wrapper #header-search-box {
  width: 90%;
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #tpl_b #header .wide #header-search-wrapper #header-search-box {
    width: 80%;
  }
}

#tpl_b #header .wide #header-search-wrapper #search-keyword {
  margin: 0 auto 0;
  border: 0px;
}

#tpl_b #header .wide #header-search-wrapper .header-search-group {
  width: 550px;
  margin: 0 auto 0;
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #tpl_b #header .wide #header-search-wrapper .header-search-group {
    width: 290px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #tpl_b #header .wide #header-search-wrapper .header-search-group {
    width: 390px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #tpl_b #header .wide #header-search-wrapper .header-search-group {
    width: 450px;
  }
}

#tpl_b #header .wide #header-search-wrapper .btn-action-search {
  color: #5c86bc;
  background-color: white;
  border: 0px;
  padding: 9px 12px;
}

#tpl_b #header .narrow .btn-left {
  margin-top: 10px;
}

#tpl_b #header .narrow .utils .btn-show-login .btn-auth-login {
  font-size: 13px;
  cursor: pointer;
}

#tpl_b {
  background-color: #fafafa !important;
}

#tpl_b #container {
  min-height: 100%;
  padding-bottom: 151px;
  background-color: #fafafa;
  position: relative;
}

@media only screen and (max-width: 708px) {
  #tpl_b #container {
    padding-top: 68px;
    margin-left: 0px !important;
    padding-bottom: 230px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #tpl_b #container {
    padding-top: 137px;
    padding-bottom: 230px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #tpl_b #container {
    padding-top: 137px;
    padding-bottom: 200px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #tpl_b #container {
    padding-top: 137px;
  }
}

@media only screen and (min-width: 1280px) {
  #tpl_b #container {
    padding-top: 137px;
  }
}

/* slider container */
.iosslider {
  /* required */
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
}

@media only screen and (max-width: 708px) {
  .iosslider {
    height: 170px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .iosslider {
    height: 247.8px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .iosslider {
    height: 285.6px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .iosslider {
    height: 337.4px;
  }
}

@media only screen and (min-width: 1280px) {
  .iosslider {
    height: 420px;
  }
}

/* slider */
.iosslider .slider {
  /* required */
  width: 100%;
  height: 100%;
}

/* slide */
.iosslider .slider .slide {
  /* required */
  float: left;
  width: 100%;
}

@media only screen and (max-width: 708px) {
  .iosslider .slider .slide {
    height: 170px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .iosslider .slider .slide {
    height: 247.8px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .iosslider .slider .slide {
    height: 285.6px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .iosslider .slider .slide {
    height: 337.4px;
  }
}

@media only screen and (min-width: 1280px) {
  .iosslider .slider .slide {
    height: 420px;
  }
}

#tpl_b {
  /*
	.slide-ui{
		position:absolute;
		top:0px;
		left:0px;
		text-align: center;
		display:none;
		width:100%;
		height:100%;
		.slider-navi-btns{
			position:fixed;
		    color: white;
		    background-color: black;	
		    cursor:pointer;	 
		    
		    
			@include respond-to(res_lv1){
			    width: 30px;
			    padding:10px 8px;
			    font-size:20px;
			    top: 35%;	
			}
			@include respond-to(res_lv2){
			    width: 40px;
			    padding:10px 8px;
			    font-size:25px;
			    top: 40%;			
			}
			@include respond-to(res_lv3){
			    width: 50px;
			    padding:10px 8px;
			    font-size:35px;
			    top: 40%;			
			}
			@include respond-to(res_lv4){
			    width: 56px;
			    padding:10px 8px;
			    font-size:40px;
			    top: 40%;	
			}
			@include respond-to(res_lv5){
			    width: 66px;
			    padding:10px 8px;
			    font-size:45px;
			    top: 40%;		
			}		    
		    
		}
		
		#slider-prev{
		    left: 0px;
		}
		#slider-next{
		    right: 0px;
		}
	}
	*/
}

#tpl_b #main-top {
  background-color: #475266;
  padding: 0px;
  position: relative;
}

#tpl_b .slider-navi-btns {
  position: absolute;
  color: white;
  background-color: black;
  cursor: pointer;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
  filter: alpha(opacity=65);
  -moz-opacity: 0.65;
  -khtml-opacity: 0.65;
  opacity: 0.65;
}

@media only screen and (max-width: 708px) {
  #tpl_b .slider-navi-btns {
    width: 30px;
    padding: 10px 8px;
    font-size: 20px;
    top: 35%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #tpl_b .slider-navi-btns {
    width: 40px;
    padding: 10px 8px;
    font-size: 25px;
    top: 40%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #tpl_b .slider-navi-btns {
    width: 50px;
    padding: 10px 8px;
    font-size: 35px;
    top: 40%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #tpl_b .slider-navi-btns {
    width: 56px;
    padding: 10px 8px;
    font-size: 40px;
    top: 40%;
  }
}

@media only screen and (min-width: 1280px) {
  #tpl_b .slider-navi-btns {
    width: 66px;
    padding: 10px 8px;
    font-size: 45px;
    top: 40%;
  }
}

#tpl_b #slider-prev {
  left: 0px;
}

#tpl_b #slider-next {
  right: 0px;
}

#tpl_b .slide-link {
  position: absolute;
  text-align: center;
}

@media only screen and (max-width: 708px) {
  #tpl_b .slide-link {
    font-size: 45px;
    top: 30%;
    left: 44%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #tpl_b .slide-link {
    font-size: 50px;
    top: 40%;
    left: 47%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #tpl_b .slide-link {
    font-size: 55px;
    top: 40%;
    left: 48%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #tpl_b .slide-link {
    font-size: 65px;
    top: 40%;
    left: 48%;
  }
}

@media only screen and (min-width: 1280px) {
  #tpl_b .slide-link {
    font-size: 65px;
    top: 40%;
    left: 48%;
  }
}

#tpl_b .slide-link a {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
  filter: alpha(opacity=65);
  -moz-opacity: 0.65;
  -khtml-opacity: 0.65;
  opacity: 0.65;
  color: white;
}

#tpl_b .slide-link a:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  color: white;
}

#tpl_b #slide-control-bar {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background-color: black;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
  filter: alpha(opacity=65);
  -moz-opacity: 0.65;
  -khtml-opacity: 0.65;
  opacity: 0.65;
}

#tpl_b #slide-control-bar #slide-pager {
  width: 100%;
  text-align: center;
}

#tpl_b #slide-control-bar #slide-pager ul {
  margin: 8px auto;
}

#tpl_b #slide-control-bar #slide-pager ul .item {
  width: 25px;
  cursor: pointer;
  float: left;
  text-align: center;
  color: #c0c0c0;
}

#tpl_b #slide-control-bar #slide-pager ul .item.selected {
  color: white;
}

#tpl_b #slide-control-bar #slide-pager ul:after {
  content: ' ';
  display: block;
  clear: both;
}

.slide {
  position: relative;
}

.slide .slide-cover-image {
  height: 100%;
  background-size: cover;
  background-position: center center;
  /*
		@include respond-to(res_lv1){
			height:$slideHeight_lv1;
		}
		@include respond-to(res_lv2){
			height:$slideHeight_lv2;
		}
		@include respond-to(res_lv3){
			height:$slideHeight_lv3;
		}
		@include respond-to(res_lv4){
			height:$slideHeight_lv4;
		}
		@include respond-to(res_lv5){
			height:$slideHeight_lv5;
		}
		*/
}

.slide .slide-text {
  position: absolute;
  color: white;
  padding: 20px;
  font-size: 20px;
  background: none repeat scroll 0 0 #000000;
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: left;
}

@media only screen and (max-width: 708px) {
  .slide .slide-text {
    font-size: 12px;
    padding: 8px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .slide .slide-text {
    font-size: 15px;
    padding: 10px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .slide .slide-text {
    font-size: 17px;
    padding: 13px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .slide .slide-text {
    font-size: 19px;
    padding: 15px;
  }
}

@media only screen and (min-width: 1280px) {
  .slide .slide-text {
    font-size: 22px;
    padding: 15px;
  }
}

.slide .slide-title {
  left: 10%;
}

@media only screen and (max-width: 708px) {
  .slide .slide-title {
    top: 10px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .slide .slide-title {
    top: 15px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .slide .slide-title {
    top: 20px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .slide .slide-title {
    top: 25px;
  }
}

@media only screen and (min-width: 1280px) {
  .slide .slide-title {
    top: 30px;
  }
}

.slide .slide-desc {
  left: 10%;
  max-width: 45%;
  overflow: hidden;
}

@media only screen and (max-width: 708px) {
  .slide .slide-desc {
    top: 55px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  .slide .slide-desc {
    top: 80px;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  .slide .slide-desc {
    top: 100px;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  .slide .slide-desc {
    top: 120px;
  }
}

@media only screen and (min-width: 1280px) {
  .slide .slide-desc {
    top: 130px;
    max-height: 80px;
  }
}

/*
#tpl_b{
	#main-top{
		margin-top:20px;
		background-color:#475266;
		padding:0px;
		position:relative;
		#top-vod{
			position:relative;
			float:none;
			width:100%;
			.slide-ui{
				display:block;
				#slider-prev{
						left:inherit;
						top:inherit;
						right: 120px;
						bottom: 20px;
						z-index: 100;				
					@include respond-to(res_lv1){
						right: 35px;
						bottom: 10px;
					}
					@include respond-to(res_lv2){
						
					}
					@include respond-to(res_lv3){
			
					}
					@include respond-to(res_lv4){
			
					}
					@include respond-to(res_lv5){
			
					}
					a{
						font-size:45px;
					}
					
				}
				#slider-next{
						right:inherit;
						top:inherit;
						right: 60px;
						bottom: 20px;
						z-index: 100;									
					@include respond-to(res_lv1){

					}
					@include respond-to(res_lv2){
						
					}
					@include respond-to(res_lv3){
			
					}
					@include respond-to(res_lv4){
			
					}
					@include respond-to(res_lv5){
			
					}
					
					a{
						font-size:45px; 
					}					
									
				}
			}
		}
		
		#slide-control-bar{
			position:absolute;
			bottom:40px;
			right:155px;
			
			@include respond-to(res_lv1){

			}
			@include respond-to(res_lv2){
				right:inherit;
				width:100%;
			}
			@include respond-to(res_lv3){
				right:10%;
			}
			@include respond-to(res_lv4){
	
			}
			@include respond-to(res_lv5){
				right:235px;
			}			
			
		}
		
		.slide{
			.slide-cover-image{
				width:100%; 
				@include respond-to(res_lv1){
					height:$slideHeight_lv1;
				}
				@include respond-to(res_lv2){
					height:$slideHeight_lv2;
				}
				@include respond-to(res_lv3){
					height:$slideHeight_lv3;
				}
				@include respond-to(res_lv4){
					height:$slideHeight_lv4;
				}
				@include respond-to(res_lv5){
					height:$slideHeight_lv5;
				}
				.post-list-cover-image{
					height:100%;
					background-size:cover;
					background-position: center center;
				}
			}
			.vod-info-box{
				width:35%;
				height:100%;
				position:relative;
				background-color:#475266;
				@include respond-to(res_lv1){
					height:$slideHeight_lv1;
				}
				@include respond-to(res_lv2){
					height:$slideHeight_lv2;
				}
				@include respond-to(res_lv3){
					height:$slideHeight_lv3;
				}
				@include respond-to(res_lv4){
					height:$slideHeight_lv4;
				}
				@include respond-to(res_lv5){
					height:$slideHeight_lv5;
				}				
				.slide-owner{
					position: absolute;
					top: 112px;
					left: 28px;
					a{
						
					}		 
				}			
				
			}
		}
		.bx-wrapper{
			.slide-info{
			    position: absolute;
			    top: 15px;
			    left: 0px;
			    padding-left: 20px;
			    width: 100%;
			    z-index: 1000;				
			}
		}
		
		#slide-pager{
			ul{
				li{
					a{
						font-size:12px;
					}
				}
			}
			.slide-page.active{
				color:white;
			}
		} 
	}
	
}
*/
#tpl_b #main-middle-container {
  margin-top: 20px;
}

#tpl_b #main-middle-container #main-row-1 {
  width: 68%;
}

@media only screen and (max-width: 708px) {
  #tpl_b #main-middle-container #main-row-1 {
    width: 100%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #tpl_b #main-middle-container #main-row-1 {
    width: 100%;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #tpl_b #main-middle-container #main-row-1 {
    width: 68%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #tpl_b #main-middle-container #main-row-1 {
    width: 68%;
  }
}

@media only screen and (min-width: 1280px) {
  #tpl_b #main-middle-container #main-row-1 {
    width: 72%;
  }
}

#tpl_b #main-middle-container #main-row-2 {
  width: 28%;
}

@media only screen and (max-width: 708px) {
  #tpl_b #main-middle-container #main-row-2 {
    width: 28%;
    display: none;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #tpl_b #main-middle-container #main-row-2 {
    width: 28%;
    display: none;
  }
}

@media only screen and (min-width: 817px) and (max-width: 1023px) {
  #tpl_b #main-middle-container #main-row-2 {
    width: 28%;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1279px) {
  #tpl_b #main-middle-container #main-row-2 {
    width: 28%;
  }
}

@media only screen and (min-width: 1280px) {
  #tpl_b #main-middle-container #main-row-2 {
    width: 25%;
  }
}

#tpl_b .type-a .btn-course-more {
  border: 0px;
}

#tpl_b .list-slider-box {
  position: relative;
  width: 100%;
  height: 224px;
  margin: 0 auto 20px;
}

#tpl_b .list-slider-box .postLst, #tpl_b .list-slider-box .list-slider-warpper {
  height: 224px;
  overflow: hidden;
}

#tpl_b .list-slider-box .postLst .box-post-list, #tpl_b .list-slider-box .list-slider-warpper .box-post-list {
  width: 198px;
  margin: 0px 8px 0px;
}

@media only screen and (max-width: 708px) {
  #tpl_b .list-slider-box .postLst .box-post-list, #tpl_b .list-slider-box .list-slider-warpper .box-post-list {
    width: 48%;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #tpl_b .list-slider-box .postLst .box-post-list, #tpl_b .list-slider-box .list-slider-warpper .box-post-list {
    width: 48%;
  }
}

#tpl_b .list-slider-box .btn-list-slider {
  position: absolute;
  top: 49px;
  padding-top: 10px;
  border: 1px solid #ccc;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  z-index: 99;
  background-color: white;
  color: #ccc;
  cursor: pointer;
  text-align: center;
  display: none;
  font-weight: bold;
  font-size: 15px;
  -webkit-box-shadow: 2px 5px 7px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 5px 7px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 5px 7px -2px rgba(0, 0, 0, 0.75);
  /*
			-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
			filter: alpha(opacity=75);
			-moz-opacity: 0.75;
			-khtml-opacity: 0.75;
			opacity: 0.75;
			*/
}

#tpl_b .list-slider-box .btn-list-slider .xi {
  font-weight: bold;
}

#tpl_b .list-slider-box .btn-list-slider:hover {
  background-color: #f1f1f1;
}

#tpl_b .list-slider-box .btn-list-slider-prev {
  left: -20px;
}

@media only screen and (max-width: 708px) {
  #tpl_b .list-slider-box .btn-list-slider-prev {
    left: 0px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #tpl_b .list-slider-box .btn-list-slider-prev {
    left: 0px;
  }
}

#tpl_b .list-slider-box .btn-list-slider-next {
  right: -20px;
}

@media only screen and (max-width: 708px) {
  #tpl_b .list-slider-box .btn-list-slider-next {
    right: 0px;
  }
}

@media only screen and (min-width: 709px) and (max-width: 815px) {
  #tpl_b .list-slider-box .btn-list-slider-next {
    right: 0px;
  }
}

#tpl_c #header .wide {
  height: 117px;
}

#tpl_c #header .wide #btn-master-category {
  float: none;
}
